import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Button, fade, Grid,
  makeStyles, Typography,
} from '@material-ui/core';
import { useMutation } from 'react-fetching-library';
import showServerError from 'helpers/showError';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import RoundedButton from 'components/Shared/RoundedButton';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/file_download.svg';
import { ASSESSMENT_STATUSES } from '../../Assessments/constants';
import { ChevronRight, Warning } from '@material-ui/icons';
import { checkPermission } from '../../../helpers/checkPermissions';
import permissions from '../../../config/permissions';
import CompareIcon from '@material-ui/icons/Compare';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Loader from '../../Shared/Loader';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 15,
    fontWeight: 400,
  },
  compareBtn: {
    width: 49,
    borderColor: theme.colors.lightBlue,
    color: theme.colors.lightBlue,
    marginRight: 16,

    '&:hover $notchedOutline': {
      borderColor: theme.colors.lightBlue,
    },
  },
  printBtn: {
    width: 49,
    borderColor: theme.colors.lightBlue,
  },
  printBtnLabel: {
    width: 24,
    height: 24,
  },
  billLink: {
    padding: 0,
    textTransform: 'none',
    borderRadius: theme.shape.borderRadius,
    background: fade(theme.colors.green, 0.3),
    '&:hover': {
      background: fade(theme.colors.green, 0.5),
    },
  },
  statusTitle: {
    fontSize: 14,
    letterSpacing: 2,
    color: theme.colors.green,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  arrowIcon: {
    width: 40,
    height: 40,
    color: theme.colors.green,
  },
  warningBoxStyle: {
    padding: 10,
    backgroundColor: fade(theme.colors.orange, .8),
    margin: '10px 24px',
    minWidth: 360,
    borderRadius: 5,
    border: `1 px solid ${theme.colors.orange}`,
    boxShadow: '1px 4px 15px -5px rgba(240, 95, 95, 0.97)',
    width: 'auto',
  },
  warningTextStyle: {
    color: 'white',
  },
  warningIconStyle: {
    color: 'white',
    margin: ' 5px 15px 5px 5px',
  },
  acceptResultBtnLabel: {
    color: theme.palette.common.white,
    letterSpacing: 2,
    fontWeight: 700,
  },
  acceptResult: {
    marginRight: theme.spacing(2),
    background: theme.colors.green,
    padding: theme.spacing(1.25, 2),
  },
  tabButton: {
    borderRadius: theme.spacing(2),
    padding: '3px 10px',
    width: 'auto',
    marginRight: 10,
    lineHeight: 1.4,
    fontSize: 12,
    color: theme.colors.grey,
    borderColor: theme.colors.grey,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
  },
  activeTabButton: {
    color: theme.colors.lightBlue,
    borderColor: theme.colors.lightBlue,
    backgroundColor: theme.colors.white,
  },
  toggleContainer: {
    height: 42,
  },
}));

const ContentHeader = ({
                         updateReport,
                         surveysInfo,
                         patientInfo,
                         setOpenBlockByDefault,
                         isOpenDefaultTab,
                         counterOpenTabs,
                         toggleProvidersModal,
                         status,
                         openAllBlock,
                         closeAllBlock,
                         alignment,
                         setAlignment,
                         isRightTabLoading,
                         isRightTabDisabled,
                       }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [surveyAccountPermissions, setSurveyAccountPermissions] = useState(null);
  const {
    en: {
      btn,
      errors,
      notifications,
      titles,
    },
  } = useSelector((state) => state.i18n);
  const [isReportPDFLoading, setIsReportPDFLoading] = useState(false);
  const [isBillingPDFLoading, setIsBillingPDFLoading] = useState(false);
  const {
    user,
    metadata: { currentAccountPermissions, currentAccount, childAccountPermissions },
  } = useSelector((state) => state.user);
  const { accountUuid: paramAccountUuid, assessmentUuid } = useParams();
  const isDSandChildAccountSurvey = paramAccountUuid !== currentAccount.uuid;
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  useEffect(() => {
    if (isDistributionSponsor) {
      setSurveyAccountPermissions(childAccountPermissions);
    } else {
      setSurveyAccountPermissions(currentAccountPermissions);
    }
  }, []);

  const acceptReportAction = () => ({
    method: 'POST',
    endpoint: `/accounts/${paramAccountUuid}/surveys/${assessmentUuid}/accept-results`,
  });

  const { loading, mutate } = useMetadataUpdate(useMutation, [
    acceptReportAction,
  ]);

  const handleAcceptReport = async () => {
    const { payload, error, status } = await mutate([]);
    if (error) {
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 422: {
          showServerError(dispatch, errors.validationError);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      updateReport();
    }
  };

  const getReportPDF = (link) => ({
    method: 'GET',
    endpoint: link,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/pdf',
    },
    credentials: 'include',
  });

  const { mutate: getPDFFile } = useMutation(getReportPDF);

  const downloadPDF = async (link, fileName) => {
    const { payload, error, status } = await getPDFFile(link);
    if (error) {
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 401: {
          showServerError(dispatch, notifications.notPermissionForEditingClaim);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
      setTimeout(() => {
        setIsReportPDFLoading(false);
        setIsBillingPDFLoading(false);
      }, 300);
    } else if (payload && payload.size > 0) {
      const url = window.URL.createObjectURL(new Blob([payload], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setIsReportPDFLoading(false);
      setIsBillingPDFLoading(false);
    }
  };

  const downloadBillingPDF = async (billingUUID) => {
    const link = `/accounts/${paramAccountUuid}/billings/${billingUUID}/billing-report/pdf`;
    await setIsBillingPDFLoading(true);
    const fileName = `Billing Report: ${surveysInfo.localizedTitle} - ${patientInfo.lastName} ${patientInfo.firstName} ${surveysInfo.termsAcceptedTimestamp}.pdf`;
    await downloadPDF(link, fileName);
  };

  const downloadReportPDF = async () => {
    const link = alignment === 'left'
      ? `/accounts/${paramAccountUuid}/surveys/${assessmentUuid}/report/pdf`
      : `/accounts/${paramAccountUuid}/surveys/${assessmentUuid}/survey-comparison-report/pdf`;
    await setIsReportPDFLoading(true);
    const fileName = `${alignment === 'left' ? 'Report' : 'Survey Comparison Report'}: ${surveysInfo.localizedTitle} - ${patientInfo.lastName} ${patientInfo.firstName} ${surveysInfo.termsAcceptedTimestamp}.pdf`;
    await downloadPDF(link, fileName);
  };

  const hasPermission = surveyAccountPermissions ? checkPermission(
    surveyAccountPermissions,
    isDistributionSponsor
      ? permissions.SURVEY_ACCEPT_RESULTS_SPECIFY_PROVIDER
      : permissions.SURVEY_ACCEPT_RESULTS,
  ) : false;

  return <>
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flex: 1,
      }}
      py={3}
      pl='4px'
      display='flex'
      alignItems='center'
      justifyContent='space-between'
    >
      <Box display='flex' justifyContent='flex-start'
           style={{
             minWidth: 250,
           }}
      >
        <Button
          color='primary'
          variant={'outlined'}
          className={cx(classes.tabButton, { [classes.activeTabButton]: isOpenDefaultTab })}
          onClick={setOpenBlockByDefault}
        >
          {btn.default}
        </Button>
        <Button
          color='primary'
          variant={'outlined'}
          className={cx(classes.tabButton, { [classes.activeTabButton]: counterOpenTabs === surveysInfo.surveyItems.length })}
          onClick={openAllBlock}
        >
          {btn.expandAll}
        </Button>
        <Button
          color='primary'
          variant={'outlined'}
          className={cx(classes.tabButton, { [classes.activeTabButton]: counterOpenTabs === 0 })}
          onClick={closeAllBlock}
        >
          {btn.closeAll}
        </Button>
      </Box>
      <Box style={{
        display: 'flex',
        gap: 24,
        alignItems: 'center',
      }}>
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label='text alignment'
          className={classes.toggleContainer}
        >
          <ToggleButton value='left' aria-label='left aligned'>
            <AssignmentIcon />
          </ToggleButton>
          <ToggleButton value='justify' aria-label='justified' disabled={isRightTabDisabled}>
            {isRightTabLoading ? <Loader size={20} /> : <CompareIcon />}
          </ToggleButton>
        </ToggleButtonGroup>

        <RoundedButton
          isLoading={isReportPDFLoading}
          className={classes.printBtn}
          classes={{
            label: classes.printBtnLabel,
          }}
          variant='outlined'
          loaderColor='primary'
          onClick={surveysInfo && downloadReportPDF}
          disabled={!surveysInfo}
        >
          <DownloadIcon />
        </RoundedButton>
      </Box>
    </Box>

    {status === ASSESSMENT_STATUSES.pendingReview
    && hasPermission
    && (<Grid item>
        <Box pl={3} py={3} alignItems='center'>
          <RoundedButton
            variant='contained'
            isLoading={loading}
            onClick={isDSandChildAccountSurvey
              ? () => toggleProvidersModal(true)
              : () => handleAcceptReport()
            }
            className={classes.acceptResult}
            classes={{
              label: classes.acceptResultBtnLabel,
            }}
          >
            {btn.acceptResults}
          </RoundedButton>
        </Box>
      </Grid>
    )}
    {status === ASSESSMENT_STATUSES.inProgress
    && (
      <Grid item>
        <Grid container direction='row' alignItems='center'
              className={classes.warningBoxStyle}>
          <Warning className={classes.warningIconStyle} />
          <Box>
            <Typography className={cx(classes.statusTitle, classes.warningTextStyle)}>
              {titles.assessmentInProgress}
            </Typography>
            <Typography className={cx(classes.text, classes.warningTextStyle)}>
              {titles.someDataNotAvailable}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )}
    {status === ASSESSMENT_STATUSES.completed && (
      <div style={{
        width: 360,
      }}>
        <Box px={3} py={2} alignItems='center'>
          <RoundedButton
            fullWidth
            className={classes.billLink}
            onClick={surveysInfo.billingUUID && (() => downloadBillingPDF(surveysInfo.billingUUID))}
            disabled={!surveysInfo.billingUUID}
            isLoading={isBillingPDFLoading}
          >
            <Box
              py={1}
              px={2}
              pr={0}
              display='flex'
              justifyContent='space-between'
            >
              <Box textAlign='left'>
                <Typography className={classes.statusTitle}>
                  {titles.accepted}
                </Typography>
                <Typography className={classes.text}>
                  {titles.viewBillingReport}
                </Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <Typography
                  className={classes.text}>{new Date(surveysInfo.reviewedTimestamp).toLocaleDateString()}</Typography>
                <ChevronRight className={classes.arrowIcon} />
              </Box>
            </Box>
          </RoundedButton>
        </Box>
      </div>
    )}
  </>;
};

export default ContentHeader;

