import React, { useRef, useState } from 'react';
import { Grid, makeStyles, Typography, Link, Box, Dialog } from '@material-ui/core';
import cx from 'classnames';
import TermsDialog from '../../../Dialogs/TermsDialog';
import PrivacyDialog from '../../../Dialogs/PrivacyDialog';
import { useDispatch, useSelector } from 'react-redux';
import HippaBaaDialog from '../../../Dialogs/HippaBaaDialog';
import smoothscroll from 'smoothscroll-polyfill';
import NavPinButton from './NavPinButton';
import { alpha } from '@material-ui/core/styles';
import { toggleIsSideMenuPinned } from '../../../../redux_store/reducer/reducers/appSettingsReducer';

// --------- adding smooth scrolling for click nav link ---------
smoothscroll.polyfill();

const BUILD = process.env.REACT_APP_BUILD_NUMBER;

const DIALOG_TYPE = {
  terms: 'terms',
  hippa_baa: 'HIPPA_BAA',
  privacy: 'privacy',
};

const defaultIsDialogOpenValue = {
  type: '',
  open: false,
};

const useStyles = makeStyles((theme) => ({
  navFooter: {
    transitionDelay: '0s, .2s',
    transition: 'opacity .5s ease-in-out, left 1s ease-in-out',
    position: 'absolute',
    opacity: 0,
    bottom: 0,
    left: -300,
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    marginRight: 0,
  },
  pinnedNavFooter: {
    transitionDelay: '0s, .2s',
    transition: 'left .1s ease-in-out, opacity .5s ease-in-out ',
    opacity: 1,
    left: 0,
    position: 'absolute',
  },
  text: {
    fontSize: 14,
    color: theme.palette.common.white,
  },
  link: {
    cursor: 'pointer',
  },
  divider: {
    width: 1,
    height: 16,
    backgroundColor: alpha(theme.palette.common.white, .5),
    margin: theme.spacing(0, 1),
  },
  animatedItem: {
    animation: `$myEffect 300ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedItemExiting: {
    display: 'none',
    animation: `$myEffectExit 300ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0,
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes myEffectExit': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flex: 1,
  }
}));

const NavFooter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { en: { nav } } = useSelector((state) => state.i18n);
  const divider = <div className={classes.divider} />;
  const [isDialogOpen, setDialogOpen] = useState(defaultIsDialogOpenValue);
  const { isSideMenuPinned } = useSelector((state) => state.appSettings);
  const hippaBaaRef = useRef(null);
  const termsRef = useRef(null);
  const privacyRef = useRef(null);
  const {
    metadata: {
      currentAccount: { businessName },
    },
  } = useSelector((state) => state.user);

  let env = '';
  switch (process.env.REACT_APP_ENV) {
    case 'development': {
      env = nav.dev;
      break;
    }
    case 'testing': {
      env = nav.testing;
      break;
    }
    default: {
      break;
    }
  }

  const handleTermsClick = () => {
    setDialogOpen({
      type: DIALOG_TYPE.terms,
      open: true,
    });
    scrollToTopOfDialog();
  };

  const handlePrivacyClick = () => {
    setDialogOpen({
      type: DIALOG_TYPE.privacy,
      open: true,
    });
    scrollToTopOfDialog();
  };

  const handleHippaBaaClick = () => {
    setDialogOpen({
      type: DIALOG_TYPE.hippa_baa,
      open: true,
    });
    scrollToTopOfDialog();
  };

  const scrollToTopOfDialog = () => {
    setTimeout(() => {
      if (hippaBaaRef.current) {
        hippaBaaRef.current.scrollIntoView({
          block: 'start',
          inline: 'start',
          behavior: 'smooth',
        });
      }
      if (termsRef.current) {
        termsRef.current.scrollIntoView({
          block: 'start',
          inline: 'start',
          behavior: 'smooth',
        });
      }
      if (privacyRef.current) {
        privacyRef.current.scrollIntoView({
          block: 'start',
          inline: 'start',
          behavior: 'smooth',
        });
      }
    }, 30);
  };

  const closeModal = () => {
    setDialogOpen(defaultIsDialogOpenValue);
  };

  return (
    <div className={classes.container}>
      <div className={cx(classes.navFooter, {
          [classes.pinnedNavFooter]: isSideMenuPinned,
        },
      )}>
        <Typography className={classes.text}>
          {`© ${new Date().getFullYear()} ${nav.qhsLabs}`}
        </Typography>
        <Grid container alignItems='flex-start' direction='column'>
          <Typography className={classes.text}>
            {`${nav.build}: ${BUILD}`}
          </Typography>
          {env && (
            <>
              <Typography className={classes.text}>
                {`${nav.env}: ${env}`}
              </Typography>
            </>
          )}
        </Grid>
        <Box mt={3}>
          <Dialog
            open={isDialogOpen.open}
            onClose={closeModal}
            aria-labelledby='invite-title'
            fullWidth
            maxWidth='lg'
          >
            <div
              style={{ overflow: 'auto' }}
            >
              <div
                ref={hippaBaaRef}

                className={cx(classes.animatedItem, {
                  [classes.animatedItemExiting]: isDialogOpen.type !== DIALOG_TYPE.hippa_baa,
                })}
              >
                <HippaBaaDialog
                  onClose={closeModal}
                  accountName={businessName}
                />
              </div>
              <div
                ref={termsRef}
                className={cx(classes.animatedItem, {
                  [classes.animatedItemExiting]: isDialogOpen.type !== DIALOG_TYPE.terms,
                })}
              >
                <TermsDialog
                  onClose={closeModal}
                  accountName={businessName}
                  onHippaOpen={handleHippaBaaClick}
                  onPrivacyOpen={handlePrivacyClick}
                />
              </div>

              <div
                className={cx(classes.animatedItem, {
                  [classes.animatedItemExiting]: isDialogOpen.type !== DIALOG_TYPE.privacy,
                })}
                ref={privacyRef}
              >
                <PrivacyDialog
                  onClose={closeModal}
                  accountName={businessName}
                />
              </div>
            </div>


          </Dialog>
          <Grid container alignItems='flex-start'>
            <Link
              className={cx(classes.text, classes.link)}
              onClick={handleTermsClick}
            >
              {nav.terms}
            </Link>
            {divider}
            <Link
              className={cx(classes.text, classes.link)}
              onClick={handlePrivacyClick}
            >
              {nav.privacy}
            </Link>
          </Grid>
          <Grid container alignItems='flex-start'>
            <Link
              className={cx(classes.text, classes.link)}
              onClick={handleHippaBaaClick}
            >
              {nav.hippa_baa}
            </Link>
          </Grid>
        </Box>
      </div>
      <div/>
      <NavPinButton
        isSideMenuPinned={isSideMenuPinned}
        changeIsSideMenuPinned={() => dispatch(toggleIsSideMenuPinned())}
      />
    </div>
  );
};

export default NavFooter;
