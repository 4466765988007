import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import { routes } from 'config/routes';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import RemoveDialog from 'components/Dialogs/RemoveDialog';
import useFetchList from 'hooks/useFetchList';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { useQuery, ClientContext } from 'react-fetching-library';
import showServerError from 'helpers/showError';
import { AppRouteContext } from 'contexts';
import usersPlaceholderImage from 'assets/placeholders/users.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as ResendIcon } from 'assets/icons/resend.svg';
import getFullName from 'helpers/user';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';
import getFakeDataList, { FAKE_TITLE_DATA } from '../../Shared/FakeDataGenerator';
import { FAKE_USER, USERS_COLUMNS, USERS_FIELDS } from '../constants';
import { TABLE_KEYS } from '../../../redux_store/reducer/reducers/tableColumnsReducer';
import { getLocalizedLabel } from '../../../helpers/localize';
import { CUSTOM_TAGS_OPTIONS } from '../../Shared/CustomTags';

const UsersList = () => {
  const { accountUuid } = useParams();
  const { t } = useTranslation([
    'forms',
    'tables',
    'dialogs',
    'titles',
    'notifications',
    'btn',
  ]);
  const dispatch = useDispatch();
  const [isOpenRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [filterStyle, setFilterStyle] = useState(false);
  const [removingUser, setRemovingUser] = useState({});
  const { setAppRoute, breadcrumbs } = useContext(AppRouteContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const {
    user: contextUser,
    metadata: { currentAccountPermissions },
  } = useSelector((state) => state.user);
  const clientContext = useContext(ClientContext);

  const {
    items,
    totalCount,
    reloadData,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint: `/accounts/${accountUuid}/users`,
    baseParams: { fields: USERS_FIELDS.join() },
  });

  const { query: queryRemove, loading: loadingRemove } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'DELETE',
        endpoint: `/accounts/${accountUuid}/users/${removingUser.uuid}`,
      },
      false,
    ],
  );

  const { filter } = restFetchProps;
  const [prevTotalCount, setPrevTotalCount] = useState(totalCount);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  const handleRowClick = ({ uuid }) => {
    setAppRoute({
      path: routes.accountUserEdit.path,
      params: {
        accountUuid,
        userUuid: uuid,
      },
      title: t('titles:editUser'),
    });
  };

  const handleEditItemClick = (e, { uuid }) => {
    setAppRoute({
      path: routes.accountUserEdit.path,
      params: {
        accountUuid,
        userUuid: uuid,
      },
      title: t('titles:editUser'),
    });
  };

  const handleInvitationItemClick = async (e, { uuid }) => {
    const { payload, error, status } = await clientContext.query({
      method: 'POST',
      endpoint: `/accounts/${accountUuid}/users/${uuid}/invitation`,
    });
    if (!error) {
      dispatch(
        enqueueSnackbar({
          message: t('notifications:emailInvitation'),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
    } else {
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: contextUser.uuid,
      };
      switch (status) {
        case 500: {
          switch (payload.errorCode) {
            case 1: {
              showServerError(
                dispatch,
                t('errors:tooManyInvitations'),
                '',
                5000,
              );
              break;
            }
            default: {
              showServerError(dispatch, '', options);
              break;
            }
          }
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    }
  };

  const handleRemoveItemClick = (e, user) => {
    setOpenRemoveDialog(true);
    setRemovingUser(user);
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
    setRemovingUser({});
  };

  const handleRemove = () => {
    const { uuid, firstName, lastName } = removingUser;
    if (uuid) {
      queryRemove().then(({ error, payload, status }) => {
        const name = `${firstName} ${lastName}`;
        if (!error) {
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 2000);
          reloadData();
          // TODO: add account name to the notification
          enqueueSnackbar(t('notifications:userRemoved', { name }), {
            variant: SNACKBAR_VARIANTS.success,
          });
        } else {
          setIsFailed(true);
          setTimeout(() => {
            setIsFailed(false);
          }, 2000);
          const options = payload && {
            correlationUUID: payload.correlationUUID,
            userUUID: contextUser.uuid,
          };
          switch (status) {
            case 404: {
              showServerError(dispatch, t('errors:userCouldNotFind'));
              break;
            }
            case 500: {
              switch (payload.errorCode) {
                case 1: {
                  showServerError(
                    dispatch,
                    t('errors:userCantBeRemoved', { name }),
                  );
                  break;
                }
                default: {
                  showServerError(dispatch, '', options);
                  break;
                }
              }
              break;
            }
            default: {
              showServerError(dispatch, '', options);
              break;
            }
          }
        }
        setOpenRemoveDialog(false);
        setRemovingUser({});
      });
    }
  };

  const handleUserCreate = () => {
    setAppRoute({
      path: routes.accountUserCreate.path,
      params: { accountUuid },
      title: t('titles:createUser'),
    });
  };

  const users =
    items &&
    items.map((user) => ({
      ...user,
      moreTitle: getFullName(user),
      accountCustomTags: user.accountCustomTags ? user.accountCustomTags.map(item => getLocalizedLabel(
        CUSTOM_TAGS_OPTIONS,
        item,
      )) : [],
    }));

  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
  const titleText = lastBreadcrumb ? lastBreadcrumb.title : t('titles:users');

  const isTableDataLoading = dataLoading || !users;
  const FAKE_USER_LIST = getFakeDataList(FAKE_USER);
  return (
    <ContentWrapper
      titleText={titleText}
      count={totalCount || FAKE_TITLE_DATA}
      countFiltered={filterStyle}
      actions={
        <RoundedButton
          variant="contained"
          color="primary"
          size="small"
          disableTextUppercase={true}
          onClick={handleUserCreate}
        >
          {t('btn:create')}
        </RoundedButton>
      }
    >
      <VirtualizedTable
        tableKey={TABLE_KEYS.users}
        hasColumnsSelect={true}
        fakeRows={FAKE_USER_LIST}
        rowCount={totalCount}
        rows={users}
        columns={USERS_COLUMNS}
        onRowClick={handleRowClick}
        FetchProps={{
          ...restFetchProps,
        }}
        onDataLoading={isTableDataLoading}
        currentAccountPermissions={currentAccountPermissions}
        actionProps={{
          actions: [
            {
              key: '1',
              icon: EditIcon,
              onActionClick: handleEditItemClick,
              children: t('titles:editUser'),
            },
            {
              key: '2',
              icon: ResendIcon,
              onActionClick: handleInvitationItemClick,
              children: t('btn:resendInvitation'),
              addDivider: true,
              showConditionFlag: 'canResendInvitation',
              showConditionValue: true,
            },
            {
              key: '3',
              icon: RemoveIcon,
              onActionClick: handleRemoveItemClick,
              children: t('titles:removeUser'),
              isRemove: true,
            },
          ],
        }}
        placeholder={
          <TablePlaceholder
            image={usersPlaceholderImage}
            itemsNotFoundText={t('placeholders:noItemsFound', {
              item: t('titles:users'),
            })}
            message={t('placeholders:reviseFiltersOrCreateYourFirstItem', {
              item: t('titles:user'),
            })}
          />
        }
      />

      <RemoveDialog
        open={isOpenRemoveDialog}
        onClose={handleCloseRemoveDialog}
        onSubmit={handleRemove}
        title={t('dialogs:confirmRemove', { name: t('titles:user') })}
        isLoading={loadingRemove}
        isSuccess={isSuccess}
        isFailed={isFailed}
      />
    </ContentWrapper>
  );
};

export default UsersList;
