import { createSlice } from '@reduxjs/toolkit';
import { login, logout } from './userReducer/api';
import { ERROR_DURATION } from '../../../contexts/NotificationsProvider';
import SupportButton from '../../../components/Shared/SupportButton';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import i18nInitialState from '../../../config/translations';
import { getMetrics, markHelpSeekingBehaviorAlertAsReviewed } from './chartsReducer';
import { changeAlertAcknowledgeStatusByUuids } from './alertsReducer/api';

export const ENQUEUE_SNACKBAR = 'enqueueSnackbar';
export const CLOSE_SNACKBAR = 'closeSnackbar';
export const REMOVE_SNACKBAR = 'removeSnackbar';

export const SNACKBAR_VARIANTS = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: { notifications: [] },
  reducers: {
    [ENQUEUE_SNACKBAR]: {
      reducer: (state, payload) => {
        state.notifications.push(payload.payload);
      },
      prepare(notification) {
        const payload = {
          ...notification,
          key: new Date().getTime() + Math.random(),
        };
        return { payload };
      },
    },
    [CLOSE_SNACKBAR]: {
      reducer: (state, action) => {
        const { payload } = action;
        // eslint-disable-next-line no-param-reassign
        state.notifications = state.notifications.map((notification) => {
          const shouldDismiss =
            payload.dismissAll ||
            notification.key === payload.key ||
            // eslint-disable-next-line no-underscore-dangle
            notification.options._key === payload.key;

          return shouldDismiss
            ? { ...notification, dismissed: true }
            : { ...notification };
        });
      },
      prepare: (key) => ({ payload: { key, dismissAll: !key } }),
    },
    [REMOVE_SNACKBAR]: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== payload,
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.ok) {
          if (!action.payload.data.data.authMetadata) {
            const payload = {
              message: i18nInitialState.en.errors.doNotHaveAccessToAnyAccounts,
              options: {
                variant: SNACKBAR_VARIANTS.error,
                autoHideDuration: ERROR_DURATION,
              },
              key: new Date().getTime() + Math.random(),
            };
            state.notifications.push(payload);
          }
        } else {
          const options = action.payload && {
            correlationUUID: action.payload.correlationUUID,
          };
          switch (action.payload.status) {
            case 422: {
              const payload = {
                message: i18nInitialState.en.errors.validationError,
                options: {
                  variant: SNACKBAR_VARIANTS.error,
                  autoHideDuration: ERROR_DURATION,
                },
                key: new Date().getTime() + Math.random(),
              };
              state.notifications.push(payload);
              break;
            }
            case 500: {
              switch (action.payload.errorCode) {
                case 1: {
                  const payload = {
                    message: i18nInitialState.en.userNotFound,
                    options: {
                      variant: SNACKBAR_VARIANTS.error,
                      autoHideDuration: ERROR_DURATION,
                    },
                    key: new Date().getTime() + Math.random(),
                  };
                  state.notifications.push(payload);
                  break;
                }
                case 2: {
                  const payload = {
                    message: i18nInitialState.en.errors.credentialsInvalid,
                    options: {
                      variant: SNACKBAR_VARIANTS.error,
                      autoHideDuration: ERROR_DURATION,
                    },
                    key: new Date().getTime() + Math.random(),
                  };
                  state.notifications.push(payload);
                  break;
                }
                default: {
                  const payload = {
                    message: '',
                    options: {
                      variant: SNACKBAR_VARIANTS.error,
                      autoHideDuration: ERROR_DURATION,
                      action: options
                        ? (key) => (
                          <>
                            <SupportButton options={options} />
                            <IconButton
                              onClick={() => {
                                closeSnackbar(key);
                              }}
                              size='small'
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        )
                        : undefined,
                    },
                    key: new Date().getTime() + Math.random(),
                  };
                  state.notifications.push(payload);
                  break;
                }
              }
              break;
            }
            default: {
              const payload = {
                message: '',
                options: {
                  variant: SNACKBAR_VARIANTS.error,
                  autoHideDuration: ERROR_DURATION,
                  action: options
                    ? (key) => (
                      <>
                        <SupportButton options={options} />
                        <IconButton
                          onClick={() => {
                            closeSnackbar(key);
                          }}
                          size='small'
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    )
                    : undefined,
                },
                key: new Date().getTime() + Math.random(),
              };
              state.notifications.push(payload);
              break;
            }
          }
        }
      })
      .addCase(logout.fulfilled, (state, action) => {
        if (action.payload.ok) {
        } else {
          const options = action.payload && {
            correlationUUID: action.payload.correlationUUID,
          };
          enqueueSnackbar({
            message: i18nInitialState.en.errors.unknownError,
            options: {
              variant: SNACKBAR_VARIANTS.error,
              autoHideDuration: ERROR_DURATION,
              action: options
                ? (key) => (
                  <>
                    <SupportButton options={options} />
                    <IconButton
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                      size='small'
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )
                : undefined,
            },
          });
        }
      })
      .addCase(getMetrics.fulfilled, (state, action) => {
        if (action.payload.ok) {
        } else {
          const options = action.payload && {
            correlationUUID: action.payload.correlationUUID,
            // userUUID: user.uuid,
          };
          enqueueSnackbar({
            message: i18nInitialState.en.errors.unknownError,
            options: {
              variant: SNACKBAR_VARIANTS.error,
              autoHideDuration: ERROR_DURATION,
              action: options
                ? (key) => (
                  <>
                    <SupportButton options={options} />
                    <IconButton
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                      size='small'
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )
                : undefined,
            },
          });
        }
      })
      .addCase(markHelpSeekingBehaviorAlertAsReviewed.fulfilled, (state, action) => {
        if (action.payload.ok) {} else {
          const options = action.payload && {
            correlationUUID: action.payload.correlationUUID,
          };

          const payload = {
            message: i18nInitialState.en.errors.unknownError,
            options: {
              variant: SNACKBAR_VARIANTS.error,
              autoHideDuration: ERROR_DURATION,
              action: options
                ? (key) => (
                  <>
                    <SupportButton options={options} />
                    <IconButton
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                      size='small'
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )
                : undefined,
            },
            key: new Date().getTime() + Math.random(),
          };
          state.notifications.push(payload);
        }
      })
      .addCase(changeAlertAcknowledgeStatusByUuids.fulfilled, (state, action) => {
        if (action.payload.ok) {} else {
          const options = action.payload && {
            correlationUUID: action.payload.correlationUUID,
          };

          const payload = {
            message: i18nInitialState.en.errors.unknownError,
            options: {
              variant: SNACKBAR_VARIANTS.error,
              autoHideDuration: ERROR_DURATION,
              action: options
                ? (key) => (
                  <>
                    <SupportButton options={options} />
                    <IconButton
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                      size='small'
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )
                : undefined,
            },
            key: new Date().getTime() + Math.random(),
          };
          state.notifications.push(payload);
        }
      });
  },
});

export const {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
