import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  TextField,
  MenuItem,
  Typography,
  InputAdornment,
  Menu,
  Tooltip,
} from '@material-ui/core';
import cx from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Loader from '../../Shared/Loader';

const useStyles = makeStyles((theme) => ({
  loaderStyle: {
    position: 'absolute',
    bottom: 10,
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  activitiesError: {
    color: theme.palette.error.main,
  },
  fullWidth: {
    width: '100%',
    margin: '0 !important',
  },
  statesSelect: {
    marginTop: theme.spacing(1),
    height: 44,
  },
  input: {
    textOverflow: 'ellipsis',
  },
  searchInput: {
    width: 'calc(100% - 40px)',
    margin: 20,
    marginTop: 12,
  },
  title: {
    textAlign: 'center',
  },
  fakeSelectText: {
    opacity: 0,
    position: 'absolute',
    left: 0,
  },
  icon: {
    disableRipple: true,
    color: 'rgba(0, 0, 0, 0.54)',

    '& .MuiTouchRipple-root span': {
      backgroundColor: 'transparent !important',
    },
  },
  iconContainer: {
    right: 6,
  },
  arrowIcon: {
    width: '1em',
    height: '1em',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  selectorIcon: {
    top: 'revert',
  },
}));

const uid = function() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const SimpleSelect = ({
                        filterValue,
                        dataKey,
                        label,
                        columnData: { options },
                        onChange,
                        error,
                        disabled,
                        required,
                        onFilter,
                        totalCount,
                        onLoadMoreRows,
                        clearFilter,
                        dataLoading,
                        hasSearch,
                        disabledItems,
                        initDataLoading,
                        variant,
                        InputProps,
                        placeholder,
                        disableUnderline,
                        searchLabel,
                        allowImpreciseMatch,
                      }) => {
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const classes = useStyles();
  const textInput = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const searchInput = React.useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const textInputId = uid();
  const scrolableId = uid();
  const [value, setValue] = useState({
    value: '',
    label: '',
  });
  const [filteredOptions, setNewOptions] = useState([]);


  const handleClick = () => {
    setAnchorEl(textInput.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue && setSearchValue('');
    clearFilter && clearFilter('');

    textInput?.current?.focus();
    setTimeout(() => {
      textInput?.current?.blur();
    }, 100);
  };

  useEffect(() => {
    if (filterValue === '') {
      textInput.current.value = '';
    }
  }, [filterValue]);

  useEffect(() => {
    if (Boolean(anchorEl)) {
      clearFilter && clearFilter();
    }
  }, [Boolean(anchorEl)]);

  useEffect(() => {
    if (filterValue !== value.value) {
      if (options) {
        let val = options.find((el) => el.value === filterValue);
        if (!val && allowImpreciseMatch) {
          val = options.find((el) => el.value.startsWith(filterValue + '.'));
        }
        if (val) {
          setValue({ value: val.value, label: val.label });
        } else {
          setValue({ value: '', label: '' });
        }
      }
    }
  }, [filterValue]);

  useEffect(() => {
    if ((!value.label && (filterValue || filterValue === false) && options) || (filterValue && options && (value.value !== filterValue))) {
      let val = options.find((el) => el.value === filterValue);
      if (!val && allowImpreciseMatch) {
        val = options.find((el) => el.value.startsWith(filterValue + '.'));
      }
      if (val) {
        setValue({ value: val.value, label: val.label });
        setSearchValue && setSearchValue('');
        clearFilter && clearFilter('');
      }
    }

    if (options) {
      setNewOptions(options);
    }
  }, [options]);

  const shouldShowTooltip = textInput.current?.offsetWidth < textInput.current?.scrollWidth;

  return (
    <Tooltip
      disableFocusListener={!shouldShowTooltip}
      disableHoverListener={!shouldShowTooltip}
      disableTouchListener={!shouldShowTooltip}
      title={value.label === false ? 'false' : (value.label || '')}
    >
      <div style={{
        position: 'relative',
      }}
      >
        <TextField
          id={textInputId}
          value={value.label === false ? 'false' : (value.label || '')}
          disabled={disabled}
          label={label}
          margin='dense'
          required={required}
          placeholder={placeholder}
          variant={variant || 'outlined'}
          className={cx(classes.statesSelect, classes.fullWidth)}
          error={error}
          inputRef={textInput}
          InputProps={{
            disableUnderline: disableUnderline,
            readOnly: true,
            ...InputProps,
            endAdornment: (
              <InputAdornment position='end' className={classes.iconContainer}>
                <ArrowDropDownIcon className={classes.icon} />
              </InputAdornment>
            ),
            style: {
              paddingRight: 6,
              textOverflow: 'ellipsis',
            },
            classes: {
              input: classes.input,
              icon: classes.selectorIcon,
              ...(InputProps?.classes ?? []),
            },
          }}
          onClick={disabled ? null : handleClick}
        />
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onOpen={async () => {
            if (hasSearch) {
              await new Promise(resolve => {
                setTimeout(() => {
                  resolve('');
                }, 400);
              });
              const searchRow = document.querySelectorAll('[role="option"]');
              if (searchRow && searchRow.length > 0) {
                searchRow[0].role = null;
                searchRow[0].onclick = (e) => {
                  searchInput.current.focus();
                  e.stopPropagation();
                  e.preventDefault();
                };
              }
            }
          }}
        >
          {hasSearch && <TextField
            className={classes.searchInput}
            label={searchLabel || 'Search'}
            inputRef={searchInput}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onFilter(e.target.value);
            }}
          />}
          {filteredOptions && filteredOptions.map((option) => (
            <MenuItem style={{ display: 'none' }} key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          <div
            id={scrolableId}
            style={hasSearch ? {
              height: 300,
              minHeight: 300,
              maxHeight: 300,
              width: 400,
              minWidth: 400,
              maxWidth: 400,
              overflow: 'auto',
              display: 'flex',
            } : { minWidth: 200 }}
          >
            <InfiniteScroll
              dataLength={totalCount || 100}
              next={() => {
              }}
              scrollableTarget={scrolableId}
              hasMore={true}
              onScroll={(e) => {
                if (hasSearch && e.target.scrollTop + 300 >= e.target.scrollHeight && !dataLoading && onLoadMoreRows) {
                  onLoadMoreRows();
                }
              }}
              initialScrollY={10}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {
                filteredOptions && filteredOptions.map((option) => (
                  <MenuItem value={option.value}
                            disabled={(disabledItems ?? []).includes(option.value)}
                            onClick={() => {
                              setValue({
                                label: option.label,
                                value: option.value,
                              });
                              onChange(dataKey, option.value);
                              handleClose();
                            }}
                  >
                    {option.label}
                  </MenuItem>
                ))
              }
              {
                hasSearch && (totalCount === 0 && !dataLoading) &&
                <div
                  style={{
                    height: 300,
                    width: 400,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >

                  <Typography className={classes.title}>
                    {t('titles:nothingFound')}
                  </Typography>
                </div>
              }
              {
                hasSearch && totalCount > (filteredOptions || []).length &&
                <div
                  style={{
                    height: (filteredOptions || []).length > 0 ? 50 : 300,
                    width: 400,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography className={classes.title}>
                    {t('titles:loading')}
                  </Typography>
                </div>
              }
            </InfiniteScroll>
          </div>
        </Menu>
        {
          initDataLoading && <Loader
            size={25}
            className={classes.loaderStyle}
          />
        }
      </div>
    </Tooltip>
  );
};

SimpleSelect.propTypes = {
  filterValue: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  dataKey: PropTypes.string.isRequired,
  columnData: PropTypes.shape({
    isFilterable: PropTypes.bool,
    options: PropTypes.array,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SimpleSelect.defaultProps = {
  error: '',
  filterValue: '',
  label: '',
  required: false,
  disabled: false,
};

export default SimpleSelect;
