import React from 'react';
import { Checkbox, makeStyles, TableCell } from '@material-ui/core';
import cx from 'classnames';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dataCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    padding: 0,
    borderBottom: 'none',

    '& div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  rowContainer: {
    display: 'flex',
    boxSizing: 'border-box',
    borderBottom: 'none',
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.white,
    transition: 'background-color .3s ease-in, font-weight .3s ease-in',
  },
  noClick: {
    cursor: 'initial',
    color: theme.palette.text.secondary,
  },
  warning: {
    backgroundColor: 'rgba(255, 255, 0, 0.28)',
    width: '100%',
  },
  selected: {
    fontWeight: 500,
    backgroundColor: alpha(theme.colors.blue4, 0.22),
    transition: 'background-color .3s ease-in, font-weight .3s ease-in',
  },
  resourceType: {
    display: 'flex',
  },
  resourceTypePadding: {
    width: 8,
  },
  loadingData: {
    width: '100%',
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 20,
  },
  bubblesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },
  newCellWithOpacity: {
    backgroundColor: alpha(theme.colors.blue4, .08),
    transition: 'background-color .3s ease-in, font-weight 0s ease-in',
  },
  newCell: {
    backgroundColor: alpha(theme.colors.blue4, .13),
    transition: 'background-color 0s ease-in, font-weight 0s ease-in',
    fontWeight: '600 !important',
  },
  newErrorCellWithOpacity: {
    backgroundColor: alpha(theme.colors.darkError, .08),
    transition: 'background-color .3s ease-in, font-weight 0s ease-in',
  },
  newErrorCell: {
    backgroundColor: alpha(theme.colors.darkError, .13),
    transition: 'background-color 0s ease-in, font-weight 0s ease-in',
  },
  badge: {
    position: 'absolute',
  },
}));

const StartCheckbox = ({
                         multiCheck,
                         onRowClick,
                         cellProps,
                         selected,
                         isViewed,
                         onDataLoading,
                         handleCheckboxClick,
                       }) => {
  const classes = useStyles();
  const { rowData, columnData, rowData: { uuid, wasViewed, disabledCheckbox } } = cellProps;
  return (
    <TableCell
      component='div'
      className={cx(
        classes.rowContainer,
        classes.flexContainer,
        classes.checkboxCell,
        classes.noBorder, {
          [classes.newCellWithOpacity]: wasViewed === false,
          [classes.newCell]: isViewed === false,
          [classes.newErrorCellWithOpacity]: wasViewed === false && rowData.alertType === 'critical',
          [classes.newErrorCell]: isViewed === false && rowData.alertType === 'critical',
          [classes.dataCell]: !columnData?.removeHidding,
          [classes.noClick]: (onRowClick == null && !(multiCheck && !rowData.disabledCheckbox)) || onDataLoading,
          [classes.selected]: multiCheck && selected.find((id) => id === uuid),
        },
      )}
    >
      {uuid ? (
        <Checkbox
          disabled={disabledCheckbox}
          classes={{
            root: cx(classes.checkbox, {
              [classes.noClick]: disabledCheckbox,
            }),
          }}
          checked={selected.includes(uuid)}
          inputProps={{ 'aria-labelledby': uuid }}
          onClick={(e) => handleCheckboxClick(e, uuid)}
        />
      ) : null}
    </TableCell>
  );
};

export default StartCheckbox;


