import React  from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { alpha } from '@material-ui/core/styles';
import TableCellMore from './TableCellMore';
import { MORE_WIDTH } from '../VirtualizedTable';

const useStyles = makeStyles((theme) => ({
  dataCell: {
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '100%',

    '& div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  rowContainer: {
    display: 'flex',
    boxSizing: 'border-box',
    borderBottom: 'none',
    width: '100% !important',
    height: '100%',
    backgroundColor: theme.colors.white,
    transition: 'background-color .3s ease-in, font-weight .3s ease-in',
  },
  noClick: {
    cursor: 'initial',
    color: theme.palette.text.secondary,
  },
  warning: {
    backgroundColor: 'rgba(255, 255, 0, 0.28)',
    width: '100%',
  },
  selected: {
    fontWeight: 500,
    backgroundColor: alpha(theme.colors.blue4, 0.22),
    transition: 'background-color .3s ease-in, font-weight .3s ease-in',
  },
  resourceType: {
    display: 'flex',
  },
  resourceTypePadding: {
    width: 8,
  },
  loadingData: {
    width: '100%',
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 20,
  },
  bubblesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },
  newCellWithOpacity: {
    backgroundColor: alpha(theme.colors.blue4, .08),
    transition: 'background-color .3s ease-in, font-weight 0s ease-in',
  },
  newCell: {
    backgroundColor: alpha(theme.colors.blue4, .13),
    transition: 'background-color 0s ease-in, font-weight 0s ease-in',
    fontWeight: '600 !important',
  },
  newErrorCellWithOpacity: {
    backgroundColor: alpha(theme.colors.darkError, .08),
    transition: 'background-color .3s ease-in, font-weight 0s ease-in',
  },
  newErrorCell: {
    backgroundColor: alpha(theme.colors.darkError, .13),
    transition: 'background-color 0s ease-in, font-weight 0s ease-in',
  },
  badge: {
    position: 'absolute',
  },
}));

const CellMore = ({
                    multiCheck,
                    onRowClick,
                    cellProps,
                    actionProps,
                    selected,
                    isViewed,
                    onDataLoading,
                  }) => {
  const classes = useStyles();
  const { rowData, columnData, rowData: { uuid, wasViewed } } = cellProps;
  return (
    actionProps ? (
      <TableCellMore
        rowData={rowData}
        actionProps={actionProps}
        disabledRowMenu={rowData.disabledRowMenu}
        selected={selected}
        className={cx(
          classes.rowContainer,
          classes.flexContainer,
          classes.moreCell,
          classes.noBorder,
          {
            [classes.noClick]: onRowClick == null || onDataLoading,
            [classes.selected]: multiCheck && selected.find((id) => id === uuid),
          },
        )}
      />
    ) : <div className={cx(
      classes.rowContainer,
      classes.flexContainer,
      classes.moreCell,
      classes.noBorder,
      {
        [classes.noClick]: onRowClick == null || onDataLoading,
        [classes.newCellWithOpacity]: wasViewed === false,
        [classes.newCell]: isViewed === false,
        [classes.newErrorCellWithOpacity]: wasViewed === false && rowData.alertType === 'critical',
        [classes.newErrorCell]: isViewed === false && rowData.alertType === 'critical',
        [classes.dataCell]: !columnData?.removeHidding,
        [classes.noClick]: (onRowClick == null && !(multiCheck && !rowData.disabledCheckbox)) || onDataLoading,
        [classes.selected]: multiCheck && selected.find((id) => id === uuid),
      },
    )} style={{ width: MORE_WIDTH }} />
  );
};

export default CellMore;


