import React, { useState } from 'react';
import { useQuery } from 'react-fetching-library';
import RemoveDialog from 'components/Dialogs/RemoveDialog';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import showServerError from 'helpers/showError';
import { useDispatch, useSelector } from 'react-redux';
import { getTransitionWithVariable } from '../../../redux_store/reducer/reducers/i18nReducer';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';

const OrdersRemoveDialog = ({
                              reloadData,
                              removingOrder,
                              setOpenRemoveDialog,
                              setRemovingOrder,
                              isOpenRemoveDialog,
                            }) => {
  const {
    en: {
      errors: tErrors,
      titles: tTitles,
      dialogs: tDialogs,
      notifications: tNotifications,
    },
  } = useSelector((state) => state.i18n);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const { query: queryRemove, loading: loadingRemove } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'DELETE',
        endpoint: `/accounts/${removingOrder.accountUUID}/orders/${removingOrder.uuid}`,
      },
      false,
    ],
  );

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
    setRemovingOrder({});
  };

  const handleRemove = () => {
    if (removingOrder.uuid) {
      queryRemove().then(({ error, payload, status }) => {
        if (!error) {
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 2000);
          reloadData();
          dispatch(
            enqueueSnackbar({
              message: tNotifications.orderRemoved,
              options: {
                variant: SNACKBAR_VARIANTS.success,
              },
            }),
          );
        } else {
          setIsFailed(true);
          setTimeout(() => {
            setIsFailed(false);
          }, 2000);
          const options = payload && {
            correlationUUID: payload.correlationUUID,
            userUUID: user.uuid,
          };
          switch (status) {
            case 404: {
              showServerError(dispatch, tErrors.orderCouldNotFind);
              break;
            }
            case 500: {
              switch (payload.errorCode) {
                case 1: {
                  showServerError(dispatch, tErrors.orderCantBeRemoved);
                  break;
                }
                default: {
                  showServerError(dispatch, '', options);
                  break;
                }
              }
              break;
            }
            default: {
              showServerError(dispatch, '', options);
              break;
            }
          }
        }
        setOpenRemoveDialog(false);
        setRemovingOrder({});
      });
    }
  };

  return <RemoveDialog
    open={isOpenRemoveDialog}
    onClose={handleCloseRemoveDialog}
    onSubmit={handleRemove}
    title={getTransitionWithVariable(tDialogs.confirmRemove, {
      name: tTitles.order,
    })}
    isLoading={loadingRemove}
    isSuccess={isSuccess}
    isFailed={isFailed}
  />;
};

export default OrdersRemoveDialog;
