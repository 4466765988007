import { getFormattedDate, getLocalizedDateIgnoreTime } from '../../../helpers/localize';

export default class AutoFillingRule {
  static __generateActivitiesUuid = claim => claim.activities.map((activity) => {
    const newActivity = activity;
    Object.keys(newActivity).forEach((key) => {
      if (typeof newActivity[key] === 'number') {
        newActivity[key] = newActivity[key].toFixed(2);
      }
    });

    return {
      ...newActivity,
      id: Math.random(),
      code: activity.code,
    };
  });

  static __generateActivitiesForPsychoEmotionalAndAssessment = (activities, suggestedICD, appointmentDate) => {
    const has96136CptCode =
      activities.some(item => item.code === '96136');
    if (!has96136CptCode) {
      activities.push({
        code: '96136',
        id: Math.random(),
        allowed: '0.00',
        paid: '0.00',
        icd: suggestedICD,
        pr: '0.00',
        modifier: '59',
        dos: getFormattedDate(appointmentDate ? new Date(getLocalizedDateIgnoreTime(appointmentDate, 'UTC')) : new Date()),
      });
    }
    const has96137CptCode =
      activities.some(item => item.code === '96137');
    if (!has96137CptCode) {
      activities.push({
        code: '96137',
        id: Math.random(),
        allowed: '0.00',
        paid: '0.00',
        icd: suggestedICD,
        pr: '0.00',
        dos: getFormattedDate(appointmentDate ? new Date(getLocalizedDateIgnoreTime(appointmentDate, 'UTC')) : new Date()),
      });
    }

    return activities;
  };

  static generateInitActivitiesIfNeed(claim) {
    let activitiesWithId = AutoFillingRule.__generateActivitiesUuid(claim);

    if (claim.entityKey === 'psychoEmotional' && claim.entityType === 'assessment' && (!claim.activities || !claim.activities.length)) {
      activitiesWithId = AutoFillingRule.__generateActivitiesForPsychoEmotionalAndAssessment(
        activitiesWithId,
        claim.suggestedICD,
        claim.appointmentDate,
      );
    }

    return activitiesWithId;
  }
}
