import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import React from 'react';

export const SURVEYS_HAVE_HELP_INFO = [
  'Q-Scale',
  'Asthma Control Assessment',
  'SNOT-22 Assessment',
  'Pediatric Allergy Baseline Assessment',
  'Allergy Baseline Assessment'
]

export const RESULT_ICONS = {
  'worse': <TrendingDownIcon />,
  'better': <TrendingUpIcon />,
  'insufficient': <></>,
  'same': <TrendingFlatIcon />,
};

export const RESULT_TEXT = {
  'worse': 'Worse',
  'better': 'Better',
  'insufficient': 'Insufficient',
  'same': 'Same',
};
