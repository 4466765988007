import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import smoothscroll from 'smoothscroll-polyfill';
import NavPinButton from '../../Navigation/NavSection/widgets/NavPinButton';

// --------- adding smooth scrolling for click nav link ---------
smoothscroll.polyfill();

const useStyles = makeStyles((theme) => ({
  linkTitle: {
    fontSize: 15,
    fontWeight: 400,
    marginBottom: 14,
    paddingLeft: theme.spacing(2),
    cursor: 'pointer',
    color: theme.colors.blue2,
  },
  navListContainer: {
    position: 'absolute',
    top: 92,
    width: 270,
    minWidth: 270,
    minHeight: 220,
    height: 'calc(100vh - 320px)',
    backgroundColor: 'white',
    transition: 'right 0.3s linear, box-shadow 0.8s linear',
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
  navCollapsedContainer: {
    width: 1,
    height: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 30,
    top: '50%',
  },
  navCollapsed: {
    transform: 'rotate( 90deg )',
    transition: 'opacity .2s linear',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    minWidth: 300,
    opacity: 1,
  },
  pinnedMenu: {
    width: '100%',
  },
  pointer: {
    borderRadius: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 24,
    fontWeight: 500,
    paddingTop: 30,
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: 2,
    marginRight: 2,
  },
  defaultCursor: {
    cursor: 'default',
  },
  icon: {
    color: theme.colors.lightBlue,
    fontSize: 32,
  },
  navListBox: {
    position: 'sticky',
    top: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    transition: 'opacity .2s linear',
    padding: 8,
    opacity: 1,
  },
  activeLinkTitle: {
    color: theme.colors.lightBlue,
  },
  open: {
    transform: 'rotate( 180deg )',
    transition: 'transform 150ms ease',
  },
  close: {
    transform: 'rotate( 360deg )',
    transition: 'transform 150ms ease',
  },
  lockButton: {
    position: 'absolute',
    left: 16,
    bottom: 16,
  },
  iconClassname: {
    fill: theme.colors.gray4,
  },
  withOpacity: {
    opacity: 0,
  },
}));

const ReportNavigation = ({
                            currentBlockId,
                            setCurrentBlockId,
                            blockTitles = [],
                            isOpenDefaultTabSelected,
                            setOpenBlock,
                            setRefs,
                            refs,
                            topRef,
                            isNavigationPinned,
                            setIsNavigationPinned,
                          }) => {
  const classes = useStyles();
  const [counterOpenTabs, setCounterOpenTabs] = useState(0);
  const [height, setHeight] = useState('100vh');
  const { t } = useTranslation(['titles']);
  const [isNavHovered, setIsNavHovered] = useState(false);
  const myRef = useRef(null);

  const handleClick = (title) => {
    const wasOpen = refs[title].open;
    setCurrentBlockId(title, true);

    if (!wasOpen) {
      setOpenBlock(title);
      const newRefs = { ...refs };
      newRefs[title].open = true;
      setRefs(newRefs);
      isOpenDefaultTabSelected(newRefs);
      setCounterOpenTabs(counterOpenTabs + 1);
    }

    // Wait for the section expanding animation to play
    setTimeout(() => {
      if (title === blockTitles[0]) {
        topRef.current.scrollIntoView({
          block: 'start',
          inline: 'start',
          behavior: 'smooth',
        });
        return;
      }
      refs[title].ref.current.scrollIntoView({
        block: 'start',
        inline: 'start',
        behavior: 'smooth',
      });
    }, wasOpen ? 0 : 460);
  };

  const setPageHeight = () => {
    if (document.getElementById('contentArea')) {
      setHeight(document.getElementById('contentArea').offsetHeight - 93);
    }
  };

  useEffect(() => {
    setPageHeight();
    window.addEventListener('resize', setPageHeight);
  }, []);

  return (
    <Grid
      ref={myRef}
      item xs={3}
      className={cx(classes.navListContainer)}
      style={{
        display: blockTitles.length ? null : 'none',
        right: !isNavigationPinned && !isNavHovered ? -220 : 0,
        boxShadow: isNavigationPinned ? 'none' : '1px 4px 15px -5px rgba(0, 0, 0, 0.97)',
        height: height,
      }}
      onMouseEnter={() => setIsNavHovered(true)}
      onMouseLeave={() => setIsNavHovered(false)}
    >
      <div
        className={classes.navCollapsedContainer}>
        <div className={cx(classes.navCollapsed, {
          [classes.withOpacity]: isNavigationPinned || isNavHovered,
        })}>
          <Typography className={classes.title}>{t('titles:reportNavigation')}</Typography>
        </div>
      </div>
      <Box className={cx(classes.navListBox, {
        [classes.withOpacity]: !isNavigationPinned && !isNavHovered,
      })}>
        <Box
          height={20}
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          className={classes.pointer}
        >
          <Typography className={cx(classes.title, classes.defaultCursor)}>{t('titles:reportNavigation')}</Typography>
        </Box>
        {blockTitles.map((title, index) => (
          <Typography
            key={index}
            onClick={() => handleClick(title)}
            className={cx(
              classes.linkTitle,
              title === currentBlockId && classes.activeLinkTitle,
            )}
          >
            {title}
          </Typography>
        ))}
      </Box>
      <NavPinButton
        isSideMenuPinned={isNavigationPinned}
        changeIsSideMenuPinned={() => {
          setIsNavigationPinned(!isNavigationPinned);
        }}
        className={classes.lockButton}
        iconClassname={classes.iconClassname}
      />
    </Grid>
  );
};

export default ReportNavigation;
