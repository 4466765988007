import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-fetching-library';
import { compare } from 'fast-json-patch';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import showServerError from 'helpers/showError';
import UserForm from './widgets/UserForm';
import Loader from 'components/Shared/Loader';
import { routes } from 'config/routes';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { AppRouteContext } from 'contexts';
import BackButton from 'components/Shared/BackButton';
import getFullName from 'helpers/user';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';
import { getTransitionWithVariable } from '../../../redux_store/reducer/reducers/i18nReducer';

const UserEdit = () => {
  const dispatch = useDispatch();
  const { en: { errors, notifications, btn, titles } } = useSelector((state) => state.i18n);
  const { setAppRoute } = useContext(AppRouteContext);
  const { accountUuid, userUuid } = useParams();
  const [user, setUser] = useState();
  const [originUser, setOriginUser] = useState();
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [backendErrors, setBackendErrors] = useState({});

  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}/users/${userUuid}`,
    },
    false,
  ]);

  useEffect(() => {
    query().then(({ error, payload }) => {
      if (!error) {
        setOriginUser(payload);
        setUser(payload);
      } else {
        setAppRoute({
          path: routes.notFoundProtected.path,
          clearBreadcrumbs: true,
          removeQueryString: true,
        });
      }
    });
  }, [query]);

  const userEditAction = (patches) => ({
    method: 'PATCH',
    endpoint: `/accounts/${accountUuid}/users/${userUuid}`,
    body: patches,
  });

  const { loading, mutate } = useMetadataUpdate(useMutation, [userEditAction]);

  const editUser = async (values, { setSubmitting }) => {
    const patches = compare(originUser, values);
    if (patches.length) {
      setBackendErrors({});
      const { payload, error, status } = await mutate(patches);
      if (error) {
        setIsFailed(true);
        setTimeout(() => {
          setIsFailed(false);
        }, 2000);
        const options = payload && {
          correlationUUID: payload.correlationUUID,
          userUUID: user.uuid,
        };
        switch (status) {
          case 422: {
            showServerError(dispatch, errors.validationError);
            setBackendErrors(payload.validationErrors);
            break;
          }
          default: {
            showServerError(dispatch, '', options);
            break;
          }
        }
      } else if (payload) {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
        setOriginUser(payload);
        setUser(payload);
        dispatch(
          enqueueSnackbar({
            message: getTransitionWithVariable(notifications.userEdited, {
              name: getFullName(values),
            }),
            options: {
              variant: SNACKBAR_VARIANTS.success,
            },
          }),
        );
      }
      setSubmitting(false);
    }
  };

  return user ? (
    <Formik
      initialValues={user}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={editUser}
    >
      {({ isValid, values, handleSubmit, isSubmitting, dirty }) => {
        return (
          <ContentWrapper
            titleText={titles.editUser}
            actions={
              <>
                <BackButton />
                <RoundedButton
                  variant='contained'
                  color='primary'
                  size='small'
                  disabled={!isValid || isSubmitting || !dirty}
                  onClick={handleSubmit}
                  isLoading={loading}
                  isSuccess={isSuccess}
                  isFailed={isFailed}
                >
                  {btn.save}
                </RoundedButton>
              </>
            }
          >
            <UserForm
              values={values}
              backendErrors={backendErrors}
              setBackendErrors={setBackendErrors}
            />
          </ContentWrapper>
        );
      }}
    </Formik>
  ) : (
    <ContentWrapper>
      <Loader fullHeight />
    </ContentWrapper>
  );
};

export default UserEdit;
