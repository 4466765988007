import React, { useContext, useMemo } from 'react';
import { Badge, Button, IconButton, makeStyles, Popover } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cx from 'classnames';
import { setAlertsPreFilter } from '../../../../redux_store/reducer/reducers/alertsReducer/alertsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ALERTS_TAGS_BY_CATEGORY } from '../../../Alerts/constants';
import { AppRouteContext } from '../../../../contexts';
import { routes } from '../../../../config/routes';
import NotificationsIcon from '@material-ui/icons/Notifications';

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.common.white,
  },
  alertBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: alpha(theme.colors.orange, 0.3),
    border: `1px solid ${theme.colors.orange}`,
    borderRadius: 8,
    padding: '4px 12px',
    color: theme.palette.common.white,
    gap: 24,
    cursor: 'pointer',
    marginRight: 12,

    '&:hover': {
      backgroundColor: alpha(theme.colors.orange, 0.5),
    },
  },
  alertInfoBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    marginRight: 24,
    fontWeight: 400,
  },
  infoIcon: {
    color: theme.colors.lightOrange,
  },
  arrowIcon: {
    transition: 'transform 0.2s ease-out',
    transform: 'rotate(0deg)',
    height: 24,
    width: 'auto',
  },
  arrowIconOpened: {
    transition: 'transform 0.2s ease-out',
    transform: 'rotate(-180deg)',
  },
  popupItemButton: {
    width: '100%',
    borderColor: 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 4px 8px 16px',
    color: theme.colors.blue2,
    fontSize: '1.1em',
    marginBottom: 4,
  },
  popupItem: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
  },
  popupItemTitle: {
    fontWeight: 600,
  },
  popupItemArrow: {
    transform: 'rotate( -90deg )',
    height: 32,
    width: 28,
    color: theme.palette.primary.main,
  },
  popupItemError: {
    marginRight: 8,
    color: 'white',
    fontSize: 8,
    lineHeight: '11px',
    backgroundColor: theme.palette.error.main,
    borderRadius: 20,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  countBubble: {
    color: 'white',
    fontSize: 10,
    lineHeight: '11px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 20,
    padding: '4px 7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,
  },
  paperRoot: {
    padding: 12,
    minWidth: 400,
  },
  driver: {
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    opacity: 0.2,
    marginBottom: 12,
  },
}));

const AlertSection = () => {
  const classes = useStyles();
  const {
    en: {
      titles,
    },
  } = useSelector((state) => state.i18n);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { newAlertList, totalAlertsCount } = useSelector((state) => state.alerts);
  const dispatch = useDispatch();
  const { setAppRoute } = useContext(AppRouteContext);
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper-alerts' : undefined;

  const handleItemClick = (filters) => {
    dispatch(setAlertsPreFilter(filters));
    setAppRoute({
      path: routes.alertList.path,
      title: titles.alerts,
      clearBreadcrumbs: true,
    });
    setAnchorEl(null);
  };

  const hasCritical = useMemo(
    () => Object.keys(newAlertList).some((key) => newAlertList[key].count > 0 && newAlertList[key].type === 'critical'),
      [newAlertList],
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return <>
    <IconButton onClick={(e) => handleClick(e)}>
      <Badge
        color={hasCritical ? 'error' : 'primary'}
        variant='dot'
        invisible={!totalAlertsCount}
      >
        <NotificationsIcon className={classes.icon} />
      </Badge>
    </IconButton>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement={'bottom-start'}
      onClose={() => setAnchorEl(null)}
      transitionDuration={{
        enter: 400,
        exit: 400,
      }}
      anchorOrigin={{
        vertical: 45,
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: classes.paperRoot,
      }}
    >
      {
        newAlertList ? Object.keys(newAlertList).map((key) => {
            const { type, count, totalCount } = newAlertList[key];
            return <Button
              className={cx(classes.popupItemButton)}
              variant='outlined'
              onClick={() => handleItemClick({
                alertTags: ALERTS_TAGS_BY_CATEGORY[key],
              })}
            >
              <div className={classes.popupItemTitle}>
                {key}
              </div>
              <div className={classes.popupItem}>
                {type === 'critical' && count > 0 ?
                  <div className={cx(classes.popupItemError)}>{'CRITICAL'}</div> : null}
                {count !== undefined && count !== 0 ? (count + ` Alert${count === 1 ? '' : 's'}`) : null}
                {/*{totalCount !== undefined && totalCount !== 0 ? (totalCount + ' Alerts') : null}*/}
                {/*{count > 0 ? <div className={cx(classes.countBubble)}>{count}</div> : null}*/}
                <ExpandMoreIcon className={classes.popupItemArrow} />
              </div>
            </Button>;
          },
        ) : null
      }
      <div className={cx(classes.driver)} />
      <Button className={cx(classes.popupItemButton)}
              variant='outlined'
              onClick={() => handleItemClick({})}
      >
        <div className={classes.popupItemTitle}>
          {'See All'}
        </div>
        <div className={classes.popupItem}>
          <ExpandMoreIcon className={classes.popupItemArrow} />
        </div>
      </Button>
    </Popover>
  </>;
};

export default AlertSection;

