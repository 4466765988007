import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Select from '../../../FormikFields/ComplexSelect/Select';
import { useParams } from 'react-router';
import useFetchList from '../../../../hooks/useFetchList';
import { CLAIM_REPORTS_STATUSES } from '../../../ClaimReport/constants';

const useStyles = makeStyles(() => ({}));

const ICDSelector = ({
                       cpt,
                       backendErrors,
                       updateClaimCPTInfo,
                       disabled,
                     }) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const { accountUuid } = useParams();

  const {
    items,
    totalCount,
    loading: dataLoading,
    onLoadMoreRows,
    onFilter,
    clearFilter,
  } = useFetchList({
    baseEndpoint: `/accounts/${accountUuid}/diagnosis-codes`,
    baseParams: {},
    baseFilter: cpt.icd ? {
      code: cpt.icd,
    } : {},
  });

  const updateClaimCode = (id, key, value) => {
    updateClaimCPTInfo(id, key, value);
  };

  return (
    <Select
      filterValue={cpt.icd}
      required={true}
      hasSearch={true}
      disabled={disabled}
      dataLoading={dataLoading}
      dataKey='icd'
      onLoadMoreRows={() =>
        onLoadMoreRows((items || []).length)
      }
      allowImpreciseMatch
      searchLabel={'Search by ICD code'}
      totalCount={totalCount || 0}
      columnData={{
        isFilterable: false,
        options: (items || []).map((icd) => {
          return {
            label: icd.code + ' ' + icd.description,
            value: icd.code,
          };
        }),
      }}
      onFilter={(value) => {
        onFilter('code', value);
      }}
      clearFilter={clearFilter}
      onChange={(dataKey, value) =>
        updateClaimCode(cpt.id, dataKey, value)
      }
      label={`${t('forms:icd')}`}
      labelWidth={78}
      updateValue={(dataKey, value) =>
        updateClaimCode(cpt.id, dataKey, value)
      }
      error={backendErrors}
      className={cx(classes.margin0, classes.fullWidth)}
    />
  );
};

export default ICDSelector;
