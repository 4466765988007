import PropTypes from 'prop-types';
import React from 'react';
import 'intersection-observer';
import './styles.css';
import { ANSWER_COLORS_LIST } from '../constants';
import MarkdownParser from '../../Reports/widgets/MarkdownParser';
import { RESULT_ICONS, RESULT_TEXT } from '../../Reports/constants';

const SingleSelect = ({ survey, isComparing, isTable }) => {
  const { title, value, reportContent, baseValue, currentValue, comparison } = survey;
  const valueUIClass = value ? value.valueUIClass : null;
  const valueTitle = value ? value.title : '';
  return (
    <>
      {
        isComparing ?
          <tr className='list-break-marker'>
            <td>
              <div>
                {title}
              </div>
            </td>
            <td className={baseValue?.valueUIClass}>
              <div>
                {isTable ? baseValue ?? '-' : baseValue?.title || '-'}
              </div>
            </td>
            <td className={currentValue?.valueUIClass}>
              <div>
                {isTable ? currentValue ?? '-' : currentValue?.title || '-'}
              </div>
            </td>
            <td className={comparison}>
              <div style={{
                justifyContent: comparison !== 'insufficient' ? null : 'center',
              }}>
                {
                  isTable
                    ? comparison !== '' ? comparison : '-'
                    : <>
                      {comparison !== 'insufficient' ? RESULT_TEXT[comparison] : '-'}
                      {RESULT_ICONS[comparison]}
                    </>
                }
              </div>
            </td>
          </tr> :
          <tr className='list-break-marker'>
            <td>{title}</td>
            <td>
              {valueUIClass === ANSWER_COLORS_LIST.success ? valueTitle : ''}
            </td>
            <td>
              {valueUIClass === ANSWER_COLORS_LIST.warning ? valueTitle : ''}
            </td>
            <td>
              {valueUIClass === ANSWER_COLORS_LIST.error ? valueTitle : ''}
            </td>
            <td>
              {valueUIClass === ANSWER_COLORS_LIST.usual ||
              valueUIClass === ANSWER_COLORS_LIST.info ||
              !valueUIClass
                ? valueTitle
                : ''}
            </td>
          </tr>
      }
      {reportContent && (
        <tr className='list-break-marker'>
          <td>
            <MarkdownParser content={reportContent} />
          </td>
        </tr>
      )}
    </>
  );
};

SingleSelect.propTypes = {
  survey: PropTypes.object,
};

SingleSelect.defaultProps = {
  survey: {},
};

export default SingleSelect;
