import PropTypes from 'prop-types';
import React from 'react';
import 'intersection-observer';
import './styles.css';
import { ANSWER_COLORS_LIST } from '../constants';
import MarkdownParser from '../../Reports/widgets/MarkdownParser';
import { RESULT_ICONS, RESULT_TEXT } from '../../Reports/constants';

const GridSelect = ({ survey, isComparing }) => {
  const { title, subItems, reportContent } = survey;
  return (
    <>
      <tr className='list-break-marker'>
        <td colSpan='5' className='grid-survey-title'>
          {title}
        </td>
      </tr>
      {subItems &&
      subItems.map(({ title: subTitle, value, baseValue, currentValue, comparison }) => {
        let answerTitle = value ? value.title ?? '' : '';
        let valueUIClass = value ? value.valueUIClass ?? ANSWER_COLORS_LIST.usual : ANSWER_COLORS_LIST.usual;
        return (
          isComparing ? <tr className='list-break-marker'>
              <td className='grid-survey-answer'>
                <div>
                  {subTitle}
                </div>
              </td>
              <td className={baseValue?.valueUIClass}>
                <div>
                  {baseValue?.title || '-'}
                </div>
              </td>
              <td className={currentValue?.valueUIClass}>
                <div>
                  {currentValue?.title || '-'}
                </div>
              </td>
              <td className={comparison}>
                <div style={{
                  justifyContent: comparison !== 'insufficient' ? null : 'center'
                }}>
                  {comparison !== 'insufficient' ? RESULT_TEXT[comparison] : '-'}
                  {RESULT_ICONS[comparison]}
                </div>
              </td>
            </tr>
            : <tr className='list-break-marker'>
              <td className='grid-survey-answer'>{subTitle}</td>
              <td>
                {valueUIClass === ANSWER_COLORS_LIST.success ? answerTitle : ''}
              </td>
              <td>
                {valueUIClass === ANSWER_COLORS_LIST.warning ? answerTitle : ''}
              </td>
              <td>
                {valueUIClass === ANSWER_COLORS_LIST.error ? answerTitle : ''}
              </td>
              <td
                className={
                  valueUIClass === ANSWER_COLORS_LIST.info ? 'empty-value' : ''
                }
              >
                {valueUIClass === ANSWER_COLORS_LIST.usual ||
                valueUIClass === ANSWER_COLORS_LIST.info ||
                !valueUIClass
                  ? answerTitle
                  : ''}
              </td>
            </tr>
        );
      })}
      {reportContent && (
        <tr className='list-break-marker'>
          <td>
            <MarkdownParser content={reportContent} />
          </td>
        </tr>
      )}
    </>
  );
};

GridSelect.propTypes = {
  survey: PropTypes.object,
};

GridSelect.defaultProps = {
  survey: {},
};

export default GridSelect;
