import { logout } from './reducer/reducers/userReducer/api';
import { updateIsMaintenanceMode } from './reducer/reducers/userReducer/userReducer';
import { checkIsMaintenanceMode } from '../helpers/downForMaintenance';
import { setAuthorizationExpiresTimeout } from './reducer/reducers/appSettingsReducer';

const API_ROOT_URI = `${process.env.REACT_APP_API_URL}`;

export async function client(
  endpoint,
  { body, method, dispatch, ...customConfig } = {},
) {
  const headers = {
    'Authorization-SetAsCookie': 'true',
    'Content-Type': 'application/json',
    'Client': 'dashboard',
    'Cache-Control': 'no-store, no-cache, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0',
  };

  const config = {
    method: method || 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    credentials: 'include',
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;
  try {
    const response = await window.fetch(`${API_ROOT_URI}${endpoint}`, config);
    data = await response.json();

    if (response.status === 500 && data.errorCode === 2000) {
      dispatch(logout());
    }
    if (response.headers.has('Authorization-Expires')) {
      dispatch(setAuthorizationExpiresTimeout(response.headers.get('Authorization-Expires')));
    }

    if (response.status === 410) {
      console.log(response);
      window.location.href = '/login#logout';
    }

    if (!response.ok) {
      const response = await checkIsMaintenanceMode();
      if (response.status === 200) {
        dispatch(updateIsMaintenanceMode(true));
      }
    }

    return {
      data,
      status: response.status,
      ok: response.ok,
    };
  } catch (err) {
    return Promise.reject(err.message ? err.message : data);
  }
}

client.get = function(endpoint, body = {}, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' });
};

client.post = function(endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'POST' });
};

client.delete = function(endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'DELETE' });
};
