import PropTypes from 'prop-types';
import React from 'react';
import 'intersection-observer';
import './styles.css';
import { ANSWER_COLORS_LIST, EMPTY_SURVEY_VALUE } from '../constants';
import MarkdownParser from '../../Reports/widgets/MarkdownParser';
import { RESULT_ICONS, RESULT_TEXT } from '../../Reports/constants';

const MultiSelect = ({ survey, isComparing }) => {
  const { title, values, reportContent, baseValue, currentValue, comparison } = survey;

  if (!values.length) {
    return (
      <>
        {
          isComparing ?
            <tr className='list-break-marker'>
              <td>{title}</td>
              <td className='empty-value'>{EMPTY_SURVEY_VALUE}</td>
              <td className='empty-value'>{EMPTY_SURVEY_VALUE}</td>
              <td>{RESULT_ICONS['same']}</td>
            </tr> :
            <tr className='list-break-marker'>
              <td>{title}</td>
              <td />
              <td />
              <td />
              <td className='empty-value'>{EMPTY_SURVEY_VALUE}</td>
            </tr>
        }
        {reportContent && (
          <tr className='list-break-marker'>
            <td>
              <MarkdownParser content={reportContent} />
            </td>
          </tr>
        )}
      </>
    );
  }

  return (
    <>
      {
        isComparing ? <tr className='list-break-marker'>
            <td>
              <div>{title}</div>
            </td>
            <td>
              <div>
                {baseValue && baseValue.values
                  .filter((item) => item.valueUIClass === ANSWER_COLORS_LIST.success)
                  .map((item) => item.title)
                  .map((value) => (
                    <span>
                {`${value}`}
                      <br />
              </span>
                  ))}
              </div>
            </td>
            <td>
              <div>
                {currentValue && currentValue.values
                  .filter((item) => item.valueUIClass === ANSWER_COLORS_LIST.warning)
                  .map((item) => item.title)
                  .map((value) => (
                    <span>
                {`${value}`}
                      <br />
              </span>
                  ))}
              </div>
            </td>
            <td>
              <div style={{
                justifyContent: comparison !== 'insufficient' ? null : 'center',
              }}>
                {comparison !== 'insufficient' ? RESULT_TEXT[comparison] : '-'}
                {RESULT_ICONS[comparison]}
              </div>
            </td>
          </tr>
          : <tr className='list-break-marker'>
            <td>{title}</td>
            <td>
              {values
                .filter((item) => item.valueUIClass === ANSWER_COLORS_LIST.success)
                .map((item) => item.title)
                .map((value) => (
                  <span>
                {`${value}`}
                    <br />
              </span>
                ))}
            </td>
            <td>
              {values
                .filter((item) => item.valueUIClass === ANSWER_COLORS_LIST.warning)
                .map((item) => item.title)
                .map((value) => (
                  <span>
                {`${value}`}
                    <br />
              </span>
                ))}
            </td>
            <td>
              {values
                .filter((item) => item.valueUIClass === ANSWER_COLORS_LIST.error)
                .map((item) => item.title)
                .map((value) => (
                  <span>
                {`${value}`}
                    <br />
              </span>
                ))}
            </td>
            <td>
              {values
                .filter(
                  (item) =>
                    item.valueUIClass === ANSWER_COLORS_LIST.usual ||
                    item.valueUIClass === ANSWER_COLORS_LIST.info ||
                    !item.valueUIClass,
                )
                .map((item) => item.title)
                .map((value) => (
                  <span>
                {`${value}`}
                    <br />
              </span>
                ))}
            </td>
          </tr>
      }
      {reportContent && (
        <tr className='list-break-marker'>
          <td>
            <MarkdownParser content={reportContent} />
          </td>
        </tr>
      )}
    </>
  );
};

MultiSelect.propTypes = {
  survey: PropTypes.object,
};

MultiSelect.defaultProps = {
  survey: {},
};

export default MultiSelect;
