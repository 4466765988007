import React, { useEffect, useState } from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import cx from 'classnames';
import getStatusBadge, { getTagsColor } from 'helpers/getStatusBadge';
import { Badge } from 'components/Shared/Badge';
import { renderLocalizedData } from 'helpers/localize';
import { camelCase } from 'lodash/string';
import { RESOURCE_ICON_LIST_FOR_TABLE } from '../../Resources/constants';
import Skeleton from '@material-ui/lab/Skeleton';
import CellSimpleText from './CellContent/CellSimpleText';
import { alpha } from '@material-ui/core/styles';
import CellPlaceholder from './CellContent/CellPlaceholder';
import OnRowViewedWrapper from './OnRowViewedWrapper';

const useStyles = makeStyles((theme) => ({
  dataCell: {
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '100%',

    '& div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  rowContainer: {
    display: 'flex',
    boxSizing: 'border-box',
    borderBottom: 'none',
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.white,
    transition: 'background-color .3s ease-in, font-weight .3s ease-in',
  },
  noClick: {
    cursor: 'initial',
    color: theme.palette.text.secondary,
  },
  warning: {
    backgroundColor: 'rgba(255, 255, 0, 0.28)',
    width: '100%',
  },
  selected: {
    fontWeight: 500,
    backgroundColor: alpha(theme.colors.blue4, 0.22),
    transition: 'background-color .3s ease-in, font-weight .3s ease-in',
  },
  resourceType: {
    display: 'flex',
  },
  resourceTypePadding: {
    width: 8,
  },
  loadingData: {
    width: '100%',
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 20,
  },
  bubblesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },
  newCellWithOpacity: {
    backgroundColor: alpha(theme.colors.blue4, .08),
    transition: 'background-color .3s ease-in, font-weight 0s ease-in',
  },
  newCell: {
    backgroundColor: alpha(theme.colors.blue4, .13),
    transition: 'background-color 0s ease-in, font-weight 0s ease-in',
    fontWeight: '600 !important',
  },
  newErrorCellWithOpacity: {
    backgroundColor: alpha(theme.colors.darkError, .08),
    transition: 'background-color .3s ease-in, font-weight 0s ease-in',
  },
  newErrorCell: {
    backgroundColor: alpha(theme.colors.darkError, .13),
    transition: 'background-color 0s ease-in, font-weight 0s ease-in',
  },
  badge: {
    position: 'absolute',
  },
}));

const CustomTableCell = ({
                           onDataLoading,
                           onRowClick,
                           multiCheck,
                           selected,
                           cellProps,
                           isViewed,
                         }) => {
  const classes = useStyles();
  const {
    cellData,
    rowData,
    rowData: {
      uuid,
      wasViewed,
    },
    dataKey,
    columnData,
  } = cellProps;

  let currentValue = columnData?.formattedFunction ? columnData?.formattedFunction(cellData) : cellData;
  const withIcon = dataKey === 'resourceName';
  const withBubble = dataKey === 'accountCustomTags'
    || dataKey === 'customTags'
    || dataKey === 'patientCustomTags'
    || dataKey === 'surveyTags'
    || dataKey === 'alertTags';
  const isStatus = dataKey.toLowerCase().includes('status');

  let data;
  if (isStatus) {
    data = <Badge
      label={currentValue}
      colorType={getStatusBadge(camelCase(currentValue))}
    />;
  } else if (columnData?.hasBubble) {
    data = <Badge
      label={currentValue}
      colorType={getStatusBadge(currentValue)}
    />;
  } else if (withBubble && currentValue && currentValue.length) {
    data = <div className={classes.bubblesContainer}>
      {
        currentValue.map(item =>
          <Badge
            label={item}
            colorType={getTagsColor(item)}
          />,
        )
      }
    </div>;
  } else if (withIcon) {
    data = <div className={classes.resourceType}>
      {RESOURCE_ICON_LIST_FOR_TABLE[rowData.resourceType]}
      <div className={classes.resourceTypePadding} />
      {renderLocalizedData(currentValue ?? '-')}
    </div>;
  } else if (columnData?.customContent) {
    data = columnData?.customContent(cellProps);
  } else if (uuid) {
    data = <CellSimpleText
      value={currentValue}
      startAdornment={columnData?.startAdornment}
    />;
  } else {
    data = <CellPlaceholder />;
  }

  return (
    <TableCell
      component='div'
      className={cx(
        classes.rowContainer,
        {
          [classes.newCellWithOpacity]: wasViewed === false,
          [classes.newCell]: isViewed === false,
          [classes.newErrorCellWithOpacity]: wasViewed === false && rowData.alertType === 'critical',
          [classes.newErrorCell]: isViewed === false && rowData.alertType === 'critical',
          [classes.dataCell]: !columnData?.removeHidding,
          [classes.warning]: currentValue === 'N/A',
          [classes.noClick]: (onRowClick == null && !(multiCheck && !rowData.disabledCheckbox)) || onDataLoading,
          [classes.selected]: multiCheck && selected.find((id) => id === uuid),
        },
      )}
      variant='body'
    >
      {data}
    </TableCell>
  );
};

export default CustomTableCell;


