import React, { useContext, useState } from 'react';
import { Menu, IconButton, makeStyles } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useTranslation } from 'react-i18next';
import { AppRouteContext } from 'contexts';
import CustomMenuItem from 'components/Shared/CustomMenuItem';
import { routes } from 'config/routes';
import permissions from 'config/permissions';
import { checkPermission } from 'helpers/checkPermissions';
import { ReactComponent as UserIcon } from 'assets/icons/users.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useSelector } from 'react-redux';

const LINK_VALUE = 'link';

const useStyles = makeStyles((theme) => ({
  moreIcon: {
    color: theme.colors.lightBlue,
  },
}));

const AccountMenu = ({ ...restProps }) => {
  const { t } = useTranslation(['general', 'titles']);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const {
    metadata: { currentAccount, currentAccountPermissions },
  } = useSelector((state) => state.user);
  const { setAppRoute } = useContext(AppRouteContext);
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );
  const canManageAccountDetails = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNT_PATCH,
  );

  const canManageUsers = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNT_USERS_GET,
  );
  const showMenu = canManageAccountDetails || canManageUsers;

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAccountEdit = () => {
    setAppRoute({
      path: routes.accountEdit.path,
      params: {
        accountUuid: currentAccount.uuid,
      },
      title: t('titles:editAccount'),
      clearBreadcrumbs: true,
    });
  };

  const handleAccountUsers = () => {
    setAppRoute({
      path: routes.accountUsers.path,
      params: {
        accountUuid: currentAccount.uuid,
      },
      title: isDistributionSponsor ? `${t('titles:users')}: ${currentAccount.businessName}` : t('titles:users'),
      clearBreadcrumbs: true,
    });
  };

  return showMenu ? (
    <div {...restProps}>
      <IconButton
        size='small'
        aria-controls='account-menu'
        aria-haspopup='true'
        onClick={handleOpenMenu}
      >
        <MoreHorizIcon className={classes.moreIcon} />
      </IconButton>

      <Menu
        id='account-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        {canManageAccountDetails && (
          <CustomMenuItem
            value={LINK_VALUE}
            icon={EditIcon}
            onClick={handleAccountEdit}
          >
            {t('general:editThisAccount')}
          </CustomMenuItem>
        )}

        {canManageUsers && (
          <CustomMenuItem
            value={LINK_VALUE}
            icon={UserIcon}
            onClick={handleAccountUsers}
          >
            {t('titles:manageUsers')}
          </CustomMenuItem>
        )}
      </Menu>
    </div>
  ) : null;
};

export default AccountMenu;
