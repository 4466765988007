import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import useFetchList from 'hooks/useFetchList';
import showServerError from 'helpers/showError';
import { getLocalizedDate, getLocalizedLabel } from 'helpers/localize';
import ordersPlaceholderImage from 'assets/placeholders/orders.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ActivePatientContext } from '../../../contexts/ActivePatientProvider';
import { NULL_QUERY_DELAY } from '../../Shared/constants';
import RefreshTableButton from '../../Shared/RefreshTableButton';
import PatientActions from '../../Patients/PatientActions';
import PatientAdditionalNode from '../../Patients/PatientAdditionalNode';
import PatientInfoBlock from '../../Patients/PatientInfoBlock';
import {
  CLAIM_REPORTS_FOR_PATIENT_FIELDS,
  FIELDS,
  GET_CLAIM_REPORTS_COLUMNS,
  FAKE_CLAIM_REPORT,
} from '../constants';
import getFakeDataList, { FAKE_TITLE_DATA } from '../../Shared/FakeDataGenerator';
import { TABLE_KEYS } from '../../../redux_store/reducer/reducers/tableColumnsReducer';
import { CUSTOM_TAGS_OPTIONS } from '../../Shared/CustomTags';
import { ReactComponent as DownloadIcon } from 'assets/icons/file_download_black.svg';
import { useMutation } from 'react-fetching-library';
import { getTransitionWithVariable } from '../../../redux_store/reducer/reducers/i18nReducer';

const useStyles = makeStyles(() => ({
  paper: {
    overflow: 'hidden',
  },
}));

/*
  could be rendered in 2 cases:
  - all account Claim Reports
  - Claim Reports for a special user (patientUuid in URL params)
*/
const ClaimReportList = () => {
  const classes = useStyles();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const {
    en: {
      notifications,
      titles,
      tables,
      placeholders,
    },
  } = useSelector((state) => state.i18n);
  const dispatch = useDispatch();
  const {
    metadata: {
      currentAccount,
      currentAccountPermissions,
    },
  } = useSelector((state) => state.user);
  const [filterStyle, setFilterStyle] = useState(false);
  const [modifiedClaimReports, setModifiedClaimReports] = useState();
  const { patientUuid, patientInfo } = useContext(ActivePatientContext);
  const { user } = useSelector((state) => state.user);

  const baseEndpoint = `/accounts/${
    paramAccountUuid || currentAccount.uuid
  }/claimReports`;
  const {
    items,
    totalAccountsCount,
    totalCount,
    completionRate,
    reloadData,
    clearFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint,
    baseParams: {
      fields: (isPatientPage ? CLAIM_REPORTS_FOR_PATIENT_FIELDS : FIELDS).join(),
      q: isPatientPage ? `patientUUID:${paramsPatientUuid}` : undefined,
    },
    baseSort: {},
  });

  const { filter } = restFetchProps;
  const [prevTotalCount, setPrevTotalCount] = useState(totalCount);

  useEffect(() => {
    if (items) {
      setModifiedClaimReports(
        items.map((claimReport) => {
          return {
            ...claimReport,
            totalAmount: claimReport.totalAmount.toString(),
            totalCPTs: claimReport.totalCPTs.toString(),
            accountCustomIdentifier: claimReport.accountCustomIdentifier
              ? claimReport.accountCustomIdentifier
              : 'N/A',
            claimReportGeneratedTimestamp: getLocalizedDate(
              claimReport.claimReportGeneratedTimestamp,
            ),
            accountCustomTags: claimReport.accountCustomTags
              ? claimReport.accountCustomTags.map(item => getLocalizedLabel(
                CUSTOM_TAGS_OPTIONS,
                item,
              ))
              : [],
            moreTitle: isPatientPage
              ? `${claimReport.surveyName}`
              : claimReport.patientFirstName && claimReport.patientLastName
                ? `${claimReport.patientFirstName} ${claimReport.patientLastName}`
                : 'Actions',
          };
        }),
      );
    }
  }, [items]);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  const reloadClaimReportList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const getReportPDF = (link) => ({
    method: 'GET',
    endpoint: link,
    responseType: 'blob',
    credentials: 'include',
  });

  const { mutate: getPDFFile } = useMutation(getReportPDF);

  const downloadPDF = async (link, claimReport) => {
    const { payload, error, status } = await getPDFFile(link);
    if (error) {
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 401: {
          showServerError(dispatch, notifications.notPermissionForEditingClaim);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload && payload.size > 0) {
      const url = window.URL.createObjectURL(new Blob([payload], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Claim Report | Claim Report Generated Timestamp: ${claimReport.claimReportGeneratedTimestamp}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  const downloadReportPDF = async (e, claimReport) => {
    const link = `/accounts/${claimReport.accountUUID}/claimReports/${claimReport.uuid}/report`;
    await downloadPDF(link, claimReport);
  };

  const patientFirstName = get(patientInfo, 'firstName', '');
  const patientLastName = get(patientInfo, 'lastName', '');
  const titleText = isPatientPage
    ? (patientInfo && `${patientLastName}, ${patientFirstName}`) ||
    FAKE_TITLE_DATA
    : titles.claimReports;

  const isTableDataLoading = dataLoading || !modifiedClaimReports;
  const FAKE_CLAIM_REPORT_LIST = getFakeDataList(FAKE_CLAIM_REPORT);
  return (
    <>
      <ContentWrapper
        titleText={titleText}
        className={classes.paper}
        hasTopPaddingForSmallScreen={isPatientPage}
        underTitleText={
          isPatientPage ? (
            <PatientInfoBlock
              isForInitLoading={
                (!items && patientUuid !== paramsPatientUuid) || !patientInfo
              }
            />
          ) : (
            ''
          )
        }
        count={isPatientPage ? null : totalCount || FAKE_TITLE_DATA}
        countFiltered={filterStyle}
        actions={
          <>
            {isPatientPage ? (
              <PatientActions reloadList={() => {}} />
            ) : (
              <RefreshTableButton
                reloadData={reloadClaimReportList}
                isLoading={dataLoading}
              />
            )}
          </>
        }
        additionalNode={
          paramsPatientUuid && (
            <PatientAdditionalNode
              activeItemNumber={5}
              modifiedData={modifiedClaimReports}
              totalCount={totalCount}
              reloadData={reloadClaimReportList}
              dataLoading={dataLoading}
              historyTitle={tables.claimReportHistory}
            />
          )
        }
      >
        <VirtualizedTable
          tableKey={paramAccountUuid ? TABLE_KEYS.claimReportsDS : TABLE_KEYS.claimReports}
          hasColumnsSelect={true}
          fakeRows={FAKE_CLAIM_REPORT_LIST}
          rowCount={totalCount}
          rows={modifiedClaimReports}
          columns={GET_CLAIM_REPORTS_COLUMNS(
            isPatientPage,
            paramAccountUuid,
            totalAccountsCount,
          )}
          FetchProps={{ ...restFetchProps }}
          onDataLoading={isTableDataLoading}
          currentAccountPermissions={currentAccountPermissions}
          actionProps={{
            actions: [
              {
                key: 'downloadReport',
                icon: DownloadIcon,
                onActionClick: downloadReportPDF,
                children: titles.downloadReport,
                isDisabled: false,
              },
            ],
          }}
          placeholder={
            <TablePlaceholder
              image={ordersPlaceholderImage}
              itemsNotFoundText={getTransitionWithVariable(placeholders.noItemsFound, {
                item: titles.claimReports,
              })}
              message={getTransitionWithVariable(placeholders.reviseFiltersOrCreateYourFirstItem, {
                item: titles.claimReports,
              })}
            />
          }
        />
      </ContentWrapper>
    </>
  );
};

export default ClaimReportList;
