import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-fetching-library';
import { isEmpty } from 'lodash';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import { routes } from 'config/routes';
import RemoveDialog from 'components/Dialogs/RemoveDialog';
import RoundedButton from 'components/Shared/RoundedButton';
import useFetchList from 'hooks/useFetchList';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import showServerError from 'helpers/showError';
import { AppRouteContext } from 'contexts';
import { getLocalizedAddress, getLocalizedLabel } from 'helpers/localize';
import accountsPlaceholderImage from 'assets/placeholders/accounts.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { useDispatch, useSelector } from 'react-redux';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/file_download_black.svg';
import { NULL_QUERY_DELAY } from '../../Shared/constants';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';
import { checkPermission } from '../../../helpers/checkPermissions';
import permissions from '../../../config/permissions';
import getFakeDataList, { FAKE_TITLE_DATA } from '../../Shared/FakeDataGenerator';
import { ACCOUNT_COLUMNS, ACCOUNT_FIELDS, FAKE_ACCOUNT } from '../constants';
import { setAccounts } from '../../../redux_store/reducer/reducers/accountsReducer/accountsReducer';
import { TABLE_KEYS } from '../../../redux_store/reducer/reducers/tableColumnsReducer';
import RefreshTableButton from '../../Shared/RefreshTableButton';
import { CUSTOM_TAGS_OPTIONS } from '../../Shared/CustomTags';
import DownloadPracticeOptimizationReportModal
  from './widgets/DownloadPracticeOptimizationReportModal';

const AccountsList = () => {
  const { t } = useTranslation([
    'titles',
    'notifications',
    'btn',
    'dialogs',
    'placeholders',
  ]);
  const dispatch = useDispatch();
  const [isOpenRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [filterStyle, setFilterStyle] = useState(false);
  const [removingAccount, setRemovingAccount] = useState({});
  const { setAppRoute } = useContext(AppRouteContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const { accounts } = useSelector((state) => state.accounts);
  const [downloadAccount, setDownloadAccount] = useState(null);
  const {
    user,
    metadata: { currentAccountPermissions },
  } = useSelector((state) => state.user);
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );
  const {
    items,
    totalCount,
    reloadData,
    clearFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint: '/accounts',
    baseParams: { fields: ACCOUNT_FIELDS.join() },
  });

  const { query: queryRemove, loading: loadingRemove } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'DELETE',
        endpoint: `/accounts/${removingAccount.uuid}`,
      },
      false,
    ],
  );

  const { filter } = restFetchProps;
  const [prevTotalCount, setPrevTotalCount] = useState(totalCount);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  useEffect(() => {
    if (items !== accounts) {
      const parsedAccounts =
        items &&
        items.map((account) => ({
          ...account,
          customIdentifier: account.customIdentifier
            ? account.customIdentifier
            : 'N/A',
          address: getLocalizedAddress(account.address),
          customTags: account.customTags ? account.customTags.map(item => getLocalizedLabel(
            CUSTOM_TAGS_OPTIONS,
            item,
          )) : [],
          moreTitle: account.businessName,
        }));
      dispatch(setAccounts(parsedAccounts));
    }
  }, [items]);

  const handleRowClick = ({ uuid, businessName }) => {
    setAppRoute({
      path: routes.accountPatientsList.path,
      params: { accountUuid: uuid },
      title: `${t('titles:patients')}: ${businessName}`,
    });
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
    setRemovingAccount({});
  };

  const handleRemove = () => {
    const { uuid, businessName } = removingAccount;
    if (uuid) {
      queryRemove().then(({ error, payload, status }) => {
        if (!error) {
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 2000);
          reloadData();
          dispatch(
            enqueueSnackbar({
              message: t('notifications:accountRemoved', {
                name: businessName,
              }),
              options: {
                variant: SNACKBAR_VARIANTS.success,
              },
            }),
          );
        } else {
          setIsFailed(true);
          setTimeout(() => {
            setIsFailed(false);
          }, 2000);
          const options = payload && {
            correlationUUID: payload.correlationUUID,
            userUUID: user.uuid,
          };
          switch (status) {
            case 404: {
              showServerError(dispatch, t('errors:accountCouldNotFind'));
              break;
            }
            case 500: {
              switch (payload.errorCode) {
                case 1: {
                  showServerError(
                    dispatch,
                    t('errors:accountCantBeRemoved', { name: businessName }),
                  );
                  break;
                }
                default: {
                  showServerError(dispatch, '', options);
                  break;
                }
              }
              break;
            }
            default: {
              showServerError(dispatch, '', options);
              break;
            }
          }
        }
        setOpenRemoveDialog(false);
        setRemovingAccount({});
      });
    }
  };

  const reloadAccountsList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const handleEditItemClick = (e, { uuid }) => {
    setAppRoute({
      path: routes.accountEdit.path,
      params: { accountUuid: uuid },
      title: t('titles:editAccount'),
    });
  };

  const handleUploadPatientsClick = (e, { uuid, businessName }) => {
    setAppRoute({
      path: routes.patientUpload.path,
      params: { accountUuid: uuid, title: businessName },
      title: t('titles:patientUpload'),
    });
  };

  const handleUploadInsuranceClick = (e, { uuid, businessName }) => {
    setAppRoute({
      path: routes.insuranceUpload.path,
      params: { accountUuid: uuid, title: businessName },
      title: t('titles:insuranceUpload'),
    });
  };

  const handleUploadProvidersClick = (e, { uuid, businessName }) => {
    setAppRoute({
      path: routes.providersUpload.path,
      params: { accountUuid: uuid, title: businessName },
      title: t('titles:providersUpload'),
    });
  };

  const handleDownloadPracticeOptimizationReportClick = (e, {
    uuid,
    businessName,
  }) => setDownloadAccount({ uuid: uuid, businessName: businessName });

  const handleRemoveItemClick = (e, account) => {
    setOpenRemoveDialog(true);
    setRemovingAccount(account);
  };

  const handleUsersItemClick = (e, { uuid, businessName }) => {
    setAppRoute({
      path: routes.accountUsers.path,
      params: { accountUuid: uuid },
      title: isDistributionSponsor ? `${t('titles:users')}: ${businessName}` : t('titles:users'),
    });
  };

  const handleAccountCreate = () => {
    setAppRoute({
      path: routes.accountCreate.path,
      title: t('titles:createAccount'),
    });
  };

  const actions = () => {
    let _actions = [
      {
        key: '1',
        icon: UsersIcon,
        onActionClick: handleUsersItemClick,
        children: t('titles:editUsers'),
      },
      {
        key: '2',
        icon: EditIcon,
        onActionClick: handleEditItemClick,
        children: t('titles:editAccount'),
        addDivider: true,
      },
    ];

    const hasImportPermission = checkPermission(
      currentAccountPermissions,
      permissions.ADMIN_PATIENT_IMPORT,
    );

    if (hasImportPermission) {
      _actions = [..._actions, {
        key: _actions.length,
        icon: CloudUpload,
        onActionClick: handleUploadPatientsClick,
        children: t('titles:patientUpload'),
      }, {
        key: _actions.length,
        icon: CloudUpload,
        onActionClick: handleUploadInsuranceClick,
        children: t('titles:insuranceUpload'),
      }, {
        key: _actions.length,
        icon: CloudUpload,
        onActionClick: handleUploadProvidersClick,
        children: t('titles:providersUpload'),
        addDivider: true,
      }];
    }

    if (hasImportPermission) {
      _actions = [..._actions,
        {
          key: _actions.length,
          icon: DownloadIcon,
          onActionClick: handleDownloadPracticeOptimizationReportClick,
          children: t('titles:downloadPracticeOptimizationReport'),
          addDivider: true,
        }];
    }

    _actions = [..._actions,
      {
        key: _actions.length,
        icon: RemoveIcon,
        onActionClick: handleRemoveItemClick,
        children: t('titles:removeAccount'),
        isRemove: true,
      }];

    return _actions;
  };

  const isTableDataLoading = dataLoading || !accounts;
  const FAKE_ACCOUNT_LIST = getFakeDataList(FAKE_ACCOUNT);
  return (
    <ContentWrapper
      titleText={t('titles:accounts')}
      count={totalCount || FAKE_TITLE_DATA}
      countFiltered={filterStyle}
      actions={[
        <RefreshTableButton
          reloadData={reloadAccountsList}
          hasRightPadding
          isLoading={dataLoading}
        />,
        <RoundedButton
          key={'createAccount'}
          variant='outlined'
          color='primary'
          size='small'
          onClick={handleAccountCreate}
          disableTextUppercase={true}
          style={{
            height: 49,
          }}
        >
          {t('btn:createAccount')}
        </RoundedButton>,
      ]}
    >
      <VirtualizedTable
        tableKey={TABLE_KEYS.accounts}
        hasColumnsSelect={true}
        fakeRows={FAKE_ACCOUNT_LIST}
        rowCount={totalCount}
        rows={accounts}
        columns={ACCOUNT_COLUMNS}
        onRowClick={handleRowClick}
        FetchProps={{ ...restFetchProps }}
        currentAccountPermissions={currentAccountPermissions}
        onDataLoading={isTableDataLoading}
        actionProps={{
          actions: actions(),
        }}
        placeholder={
          <TablePlaceholder
            image={accountsPlaceholderImage}
            itemsNotFoundText={t('placeholders:noItemsFound', {
              item: t('titles:accounts'),
            })}
            message={t('placeholders:reviseFiltersOrCreateYourFirstItem', {
              item: t('titles:account'),
            })}
          />
        }
      />

      <RemoveDialog
        open={isOpenRemoveDialog}
        onClose={handleCloseRemoveDialog}
        onSubmit={handleRemove}
        title={t('dialogs:confirmRemove', { name: t('titles:account') })}
        isLoading={loadingRemove}
        isSuccess={isSuccess}
        isFailed={isFailed}
      />
      <DownloadPracticeOptimizationReportModal
        accountUUID={downloadAccount?.uuid}
        openDownloadPdfModal={!!downloadAccount?.uuid}
        accountName={downloadAccount?.businessName}
        closeDownloadPdfModal={() => {
          setDownloadAccount(null);
        }}
      />
    </ContentWrapper>
  );
};

export default AccountsList;
