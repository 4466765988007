import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import SlotCounter from 'react-slot-counter';
import CheckCircle from '@material-ui/icons/CheckCircleOutline';
import { changeAlertAcknowledgeStatusByUuids } from '../../../../redux_store/reducer/reducers/alertsReducer/api';
import { useParams } from 'react-router-dom';
import { REQUEST_STATUS } from '../../../../redux_store/constants';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../../redux_store/reducer/reducers/notificationsReducer';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: 16,
  },
  text: {
    marginRight: 16,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop: 16,
    opacity: 1,
    height: 42,
    transitionProperty: 'height, opacity',
    transitionDuration: '.2s, .2s',
    transitionDelay: '0s, .2s',
    transitionTimingFunction: 'linear',
  },
  hideButtons: {
    opacity: 0,
    height: 0,
    transitionProperty: 'opacity, height',
    transitionDuration: '.2s, .2s',
    transitionDelay: '0s, .2s',
    transitionTimingFunction: 'linear',
  },
}));


const ActionButtons = ({ isShown, selectedItems, reloadData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { en: { btn, notifications } } = useSelector((state) => state.i18n);
  const { statusChangeAcknowledged } = useSelector((state) => state.alerts);
  const { metadata: { currentAccount } } = useSelector((state) => state.user);
  const { accountUuid: paramAccountUuid } = useParams();

  const handleChange = () => {
    dispatch(
      changeAlertAcknowledgeStatusByUuids({
        accountUuid: paramAccountUuid || currentAccount.uuid,
        alertsUuids: selectedItems,
      }),
    );
  };

  useEffect(() => {
    if (statusChangeAcknowledged === REQUEST_STATUS.succeeded) {
      dispatch(
        enqueueSnackbar({
          message: notifications.acknowledgeStatusChanged,
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      reloadData();
    }
  }, [statusChangeAcknowledged]);

  return (
    <div className={cx(classes.buttonContainer, {
      [classes.hideButtons]: !isShown,
    })}>
      <Typography className={classes.text}>
        {'Total number of selected:  '}
        <SlotCounter value={selectedItems.length}
                     sequentialAnimationMode
                     useMonospaceWidth
        />
      </Typography>
      <Button
        className={classes.button}
        onClick={handleChange}
        endIcon={<CheckCircle />}
        color='primary'
        disabled={!selectedItems.length || statusChangeAcknowledged === REQUEST_STATUS.loading}
        variant={'contained'}
      >
        {btn.acknowledge}
      </Button>
      {/*<Button*/}
      {/*  className={classes.button}*/}
      {/*  onClick={() => {*/}
      {/*  }}*/}
      {/*  color='primary'*/}
      {/*  disabled={true}*/}
      {/*  variant={'contained'}*/}
      {/*>*/}
      {/*  {'Fake Action'}*/}
      {/*</Button>*/}
      {/*<Button*/}
      {/*  className={classes.button}*/}
      {/*  onClick={() => {*/}
      {/*  }}*/}

      {/*  color='primary'*/}
      {/*  disabled={true}*/}
      {/*  variant={'contained'}*/}
      {/*>*/}
      {/*  {'Fake Action'}*/}
      {/*</Button>*/}
    </div>
  );
};

ActionButtons.propTypes = {};

export default ActionButtons;
