import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  loadingData: {
    width: '100%',
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 20,
  },
}));

const OnRowViewedWrapper = (props) => {
  const classes = useStyles();
  const [isViewed, setIsViewed] = useState(null);
  const { viewedList, onDataLoading, cellProps, children } = props;
  const { rowData: { uuid, wasViewed } } = cellProps;

  useEffect(() => {
    if (wasViewed === false && !viewedList.includes(uuid) && isViewed === null) {
      setIsViewed(false);
    }
  }, [wasViewed]);

  useEffect(() => {
    if (!isViewed && (wasViewed || viewedList.includes(uuid))) {
      setTimeout(() => {
        setIsViewed(true);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (!isViewed && (wasViewed || viewedList.includes(uuid))) {
      setTimeout(() => {
        setIsViewed(true);
      }, 1000);
    }
  }, [wasViewed, viewedList]);

  if (onDataLoading) {
    return (
      <div className={classes.loadingData}>
        <div style={{ width: '100%' }}>
          <Skeleton variant='text' />
        </div>
      </div>
    );
  }

  return React.cloneElement(children, { ...props, isViewed: isViewed});
};

export default OnRowViewedWrapper;


