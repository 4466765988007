import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router';
import Breadcrumbs from 'components/Dashboard/Breadcrumbs';
import TopBar from 'components/Navigation/TopBar/TopBar';
import { AppRouteContext, UserContext } from 'contexts';
import Loader from 'components/Shared/Loader';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import BlurScreen from '../Shared/blur_screen';
import NavBar from '../Navigation/NavSection/NavBar';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  dashboard: {
    height: '100%',
    background: `linear-gradient(180deg, ${theme.colors.blue} 0%, ${theme.colors.lightBlue} 100%)`,
  },
  dashboardInner: {
    width: '100%',
    transition: 'width 0.3s',
  },
  content: {
    height: 'calc(100% - 48px)', // 112 = topBar 80 + breadcrumbs 48
    paddingRight: theme.spacing(3),
  },
  blurDashboard: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  hideBlur: {
    height: 0,
  }
}));

const Dashboard = ({ children }) => {
  const classes = useStyles();
  const { breadcrumbs } = useContext(AppRouteContext);
  const { state } = useLocation();
  const delay = state ? state.delay : 0;
  const [delayed, setDelayed] = useState(!!delay);
  const { isSideMenuPinned } = useSelector((state) => state.appSettings);
  const { changeAccountLoading } = useContext(UserContext);

  /*
    Set a delay (provides from AppRouteProvider).
    Cases: create a new list item.
  */
  useEffect(() => {
    if (delayed) {
      setTimeout(() => {
        setDelayed(false);
      }, delay);
    }
  }, []);

  return breadcrumbs.length ? (
    <Grid container wrap="nowrap" className={classes.dashboard}>
      <NavBar />
      <TopBar />
      <div className={classes.dashboardInner} id={'dashboardInner'}
           style={{
             width: `calc(100% - ${isSideMenuPinned ? 240 : 80}px)`,
             transition: '0.3s',
           }}
      >
        <BlurScreen isBlurShown={changeAccountLoading} containerStyle={{paddingTop: 80,}}>
          <Breadcrumbs />
          <Grid container className={classes.content}>
            {delayed ? (
              <ContentWrapper>
                <Loader fullHeight />
              </ContentWrapper>
            ) : (
              children
            )}
          </Grid>
        </BlurScreen>
      </div>
    </Grid>
  ) : (
    <Loader fullHeight />
  );
};

Dashboard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Dashboard;
