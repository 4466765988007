import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    minHeight: 32,
    maxHeight: 32,
    paddingRight: 6,

    '& .MuiOutlinedInput-input': {
      padding: '0px !important',
      fontSize: 14,
      fontWeight: 400,
    },
  },
  lightColorBorder: {
    borderColor: 'transparent !important',
  },
}));

const PriceFilter = (props) => {
  const [value, setValue] = useState('');
  const {
    filterValue,
    dataKey,
    onChange,
  } = props;

  useEffect(() => {
    if (filterValue === '') {
      setValue('');
    } else if (value === '' && filterValue !== null) {
      setValue(filterValue);
    }
  }, [filterValue]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value.replace('$ ', '');
      if (onChange) {
        onChange(dataKey, value, true);
      }
    }
  };

  return <CurrencyFormat
    prefix={'$ '}
    value={value}
    customInput={_CustomInput}
    allowNegative={false}
    fixedDecimalScale={true}
    decimalScale={2}
    onKeyDown={handleKeyDown}
    thousandSeparator={','}
    onValueChange={(values) => {
      const { value } = values;
      setValue(value);
      onChange(dataKey, value, true);
    }}
  />;
};

PriceFilter.propTypes = {
  filterValue: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  columnData: PropTypes.shape({
    isFilterable: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
};

PriceFilter.defaultProps = {
  filterValue: '',
  onChange: undefined,
};

export default PriceFilter;

const _CustomInput = (props) => {
  const { en: { tables } } = useSelector((state) => state.i18n);
  const classes = useStyles();
  return <TextField
    autoComplete='off'
    fullWidth
    placeholder={!props.value ? tables.filter : ''}
    variant={'outlined'}
    InputProps={{
      classes: {
        notchedOutline: classes.lightColorBorder,
        root: classes.root,
      },
    }}
    {...props}
  />;
};
