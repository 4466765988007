import React, { useContext, useEffect } from 'react';
import { get } from 'lodash';
import { Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-fetching-library';
import PropTypes from 'prop-types';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import { formatPhoneNumber } from '../../helpers/phone';
import useMetadataUpdate from '../../hooks/useMetadataUpdate';
import { getLocalizedDate } from '../../helpers/localize';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  dot: {
    padding: theme.spacing(0, 0.5),
    color: theme.colors.lightBlue,
  },
  patientInfoBox: {
    display: 'flex',
  },
}));

const PatientInfoBlock = ({ isReportPage, isForInitLoading, patientUUID }) => {
  const classes = useStyles();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid) || isReportPage;
  const {
    patientUuid,
    patientInfo,
    setPatientUuid,
    setPatientInfo,
  } = useContext(ActivePatientContext);

  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${paramAccountUuid}/patients/${
        paramsPatientUuid || patientUUID
      }`,
    },
    false,
  ]);

  useEffect(() => {
    const hasParamsAccountUuid = !!paramAccountUuid;
    const hasPropsPatientUUID = !!patientUUID;
    const hasParamsPatientUUID = !!paramsPatientUuid;
    const hasPagePatientUUID = hasPropsPatientUUID || hasParamsPatientUUID;
    const patientUuidDontMatch = hasParamsPatientUUID
      ? patientUuid !== paramsPatientUuid
      : patientUuid !== patientUUID;
    const hasEnoughInfoForRequest = hasParamsAccountUuid && hasPagePatientUUID;
    const shouldMakeRequest =
      (isPatientPage && patientUuidDontMatch) || !patientInfo;

    if (hasEnoughInfoForRequest && shouldMakeRequest) {
      if (patientInfo) setPatientInfo(undefined);
      if (isPatientPage && isForInitLoading) {
        query().then(({ error, payload }) => {
          if (!error) {
            setPatientUuid(paramsPatientUuid);
            setPatientInfo({
              ...payload,
              dateOfBirth: getLocalizedDate(payload.dateOfBirth, 'UTC'),
              phone: formatPhoneNumber(
                payload.phone != null ? payload.phone.phone : '',
              ),
            });
          }
        });
      }
    }
  }, [patientUuid, paramsPatientUuid, patientUUID]);

  const patientInf = [
    get(patientInfo, 'dateOfBirth', ''),
    get(patientInfo, 'address.streetAddress', ''),
    get(patientInfo, 'address.city', ''),
    `${get(patientInfo, 'address.state', '')} ${get(
      patientInfo,
      'address.zipCode',
      '',
    )}`,
    get(patientInfo, 'phone.phone', ''),
  ].filter((item) => item);

  if (process.env.REACT_APP_ENV !== 'production') {
    console.log(patientInf);
  }
  return (
    <>
      {!patientInf ||
      (patientInf[0] === ' ' && (
        <Grid
          item
          className={classes.titleTextBox}
          style={{
            width: '100%',
            minWidth: 200,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              width: '50%',
            }}
          >
            <Skeleton variant='text' />
          </div>
          <div
            style={{
              width: '80%',
            }}
          >
            <Skeleton variant='text' />
          </div>
        </Grid>
      ))}
      {patientInf && patientInf.join('').replaceAll(' ', '') !== 'test' &&
      patientInf.map((item, i) => (
        <span key={item} className={classes.patientInfoBox}>
            {item}
          {patientInf.length - 1 !== i && (
            <span className={classes.dot}>•</span>
          )}
          </span>
      ))}
    </>
  );
};

PatientInfoBlock.propTypes = {
  isReportPage: PropTypes.bool,
  patientUUID: PropTypes.string,
  isForInitLoading: PropTypes.bool,
};

PatientInfoBlock.defaultProps = {
  isReportPage: false,
  isForInitLoading: false,
  patientUUID: null,
};

export default PatientInfoBlock;
