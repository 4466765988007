import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '0 6px',
    fontSize: 12,
  },
  automationIcon: {
    color: theme.colors.gray4,
    position: 'absolute',
    width: 20,
    right: -10,
    top: -10,
    '&$checked': {
      color: theme.colors.gray4,
    },
  },
  button: {
    minHeight: 36,
  },
  toggleContainer: {
    marginRight: 16,
  },
}));


const MultiCheckModeButtons = ({ isMultiCheckModeOn, onSwitchMode }) => {
  const dispatch = useDispatch();
  const {
    en: { tables },
  } = useSelector((state) => state.i18n);
  const classes = useStyles();

  const handleChange = (e) => {
    onSwitchMode(!isMultiCheckModeOn)
  }

  return (
    <>
      <ToggleButtonGroup classes={{ root: classes.toggleContainer }} size='medium' value={isMultiCheckModeOn ? 'left' : 'right'} exclusive onChange={handleChange}>
        <ToggleButton value='left' classes={{ root: classes.button }}>
          <Typography className={classes.title}>
            {tables.multiLineActionMode}
          </Typography>
        </ToggleButton>
        <ToggleButton value='right' classes={{ root: classes.button }}>
          <Typography className={classes.title}>
            {tables.regularMode}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

MultiCheckModeButtons.propTypes = {
};

export default MultiCheckModeButtons;
