import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useMutation } from 'react-fetching-library';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import showServerError from 'helpers/showError';
import { routes } from 'config/routes';
import UserForm from './widgets/UserForm';
import { AppRouteContext } from 'contexts';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import BackButton from 'components/Shared/BackButton';
import getFullName from 'helpers/user';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';
import { getTransitionWithVariable } from '../../../redux_store/reducer/reducers/i18nReducer';

const UserCreate = () => {
  const dispatch = useDispatch();
  const { accountUuid } = useParams();
  const { setAppRoute } = useContext(AppRouteContext);
  const { user } = useSelector((state) => state.user);
  const { en: { errors, notifications, btn, titles } } = useSelector((state) => state.i18n);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [backendErrors, setBackendErrors] = useState({});

  const userCreateAction = (values) => ({
    method: 'PUT',
    endpoint: `/accounts/${accountUuid}/users/`,
    body: values,
  });

  const { loading, mutate } = useMetadataUpdate(useMutation, [
    userCreateAction,
  ]);

  const createUser = async (values, { setSubmitting }) => {
    setBackendErrors({});
    const { payload, error, status } = await mutate(values);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      setSubmitting(false);
      switch (status) {
        case 422: {
          showServerError(dispatch, errors.validationError);
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      dispatch(
        enqueueSnackbar({
          message: getTransitionWithVariable(notifications.userCreated, {
            name: getFullName(values),
          }),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      // no title needed cause this route should be in breadcrumbs already
      setAppRoute(
        { path: routes.accountUsers.path, params: { accountUuid } },
        { addDelay: true },
      );
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        providerTitle: '',
        providerNPINumber: '',
        userPermissions: {
          managesUsers: false,
          administerClaims: false,
          receiveClaimsEmails: false,
          receiveSurveysEmails: false,
          administerClaimReports: false,
          imminentRiskEmails: false,
          managesAlert: false,
        },
      }}
      onSubmit={createUser}
    >
      {({ isValid, values, handleSubmit, isSubmitting, dirty }) => (
        <ContentWrapper
          titleText={titles.createUser}
          actions={
            <>
              <BackButton />
              <RoundedButton
                variant='contained'
                color='primary'
                size='small'
                disabled={!isValid || isSubmitting || !dirty}
                onClick={handleSubmit}
                isLoading={loading}
                isSuccess={isSuccess}
                isFailed={isFailed}
              >
                {btn.create}
              </RoundedButton>
            </>
          }
        >
          <UserForm
            values={values}
            backendErrors={backendErrors}
            setBackendErrors={setBackendErrors}
          />
        </ContentWrapper>
      )}
    </Formik>
  );
};

export default UserCreate;
